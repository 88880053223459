import Vue from 'vue';

import App from './App.vue';
import i18n from './locale';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import BaseAlertMessage from '@/components/BaseAlertMessage.vue';
import BaseCopyToClipboard from '@/components/BaseCopyToClipboard.vue';
import BaseErrorMessage from '@/components/BaseErrorMessage.vue';
import BaseFormNumberField from '@/components/BaseFormNumberField.vue';
import BaseFormTextField from '@/components/BaseFormTextField.vue';
import BaseListRouteItem from '@/components/BaseListRouteItem.vue';
import BaseLoadingIndicator from '@/components/BaseLoadingIndicator.vue';
import BaseSmallWindow from '@/components/BaseSmallWindow.vue';
import TheJsonViewer from '@/components/TheJsonViewer.vue';
import TheTxAssertModal from '@/components/TheTxAssertModal.vue';

Vue.component('base-alert-message', BaseAlertMessage);
Vue.component('base-copy-to-clipboard', BaseCopyToClipboard);
Vue.component('base-error-message', BaseErrorMessage);
Vue.component('base-form-number-field', BaseFormNumberField);
Vue.component('base-form-text-field', BaseFormTextField);
Vue.component('base-list-route-item', BaseListRouteItem);
Vue.component('base-loading-indicator', BaseLoadingIndicator);
Vue.component('base-small-window', BaseSmallWindow);
Vue.component('the-json-viewer', TheJsonViewer);
Vue.component('the-tx-assert-modal', TheTxAssertModal);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
