import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import IconCommercio from '@/components/icons/IconCommercio.vue';
import { SETTINGS } from '@/constants';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    values: {
      commercio: {
        component: IconCommercio,
        props: {
          height: 99.4,
          width: 102.9,
        },
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: SETTINGS.PRIMARY_COLOR,
        background: colors.grey.lighten5,
        backgroundFooter: colors.blueGrey.lighten5,
      },
    },
  },
});
