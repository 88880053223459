const labels = {
  add: 'Add',
  address: 'Address',
  allowance: 'Allowance',
  amount: 'Amount',
  animationUrl: 'Animation URL',
  args: 'Args',
  availables: 'Availables',
  backgroundColor: 'Background color',
  balance: 'Balance',
  cap: 'Cap',
  ccc: 'CCC',
  code: 'Code',
  com: 'COM',
  connectWallet: 'Connect wallet',
  contract: 'Contract',
  contractAddress: 'Contract address',
  date: 'Date',
  decimals: 'Decimals',
  decrease: 'Decrease',
  denom: 'Denom',
  description: 'Description',
  displayType: 'Display type',
  edit: 'Edit',
  expectedAmount: 'Expected amount',
  export: 'Export',
  externalUrl: 'External URL',
  fromToken: 'From token',
  hash: 'Hash',
  height: 'Height',
  image: 'Image',
  imageData: 'Image data',
  increase: 'Increase',
  info: 'Info',
  initialBalances: 'Initial balances',
  instantiateContract: 'Instantiate contract',
  instantiateDex: 'Instantiate Dex',
  lang: 'Language',
  liquidityPoolShortened: 'Liq. pool',
  logo: 'Logo',
  logoUrl: 'Logo URL',
  lpTokenToBurn: 'LP token to burn',
  manage: 'Manage',
  marketing: 'Marketing',
  max: 'Max',
  message: 'Message',
  migrate: 'Migrate',
  minLiquidity: 'Min liquidity',
  minOutputToken: 'Min Output token',
  mint: 'Mint',
  minter: 'Minter',
  minterAddress: 'Minter address',
  msgTypes: 'Message types',
  name: 'Name',
  new: 'New',
  operation: 'Operation',
  owner: 'Owner',
  project: 'Project',
  protocol: 'Protocol',
  query: 'Query',
  rank: 'Rank',
  recipient: 'Recipient',
  refresh: 'Refresh',
  remove: 'Remove',
  reserve: 'Reserve',
  reverse: 'Reverse',
  save: 'Save',
  search: 'Search',
  select: 'Select',
  share: 'Share',
  signOut: 'Sign out',
  supply: 'Supply',
  swap: 'Swap',
  symbol: 'Symbol',
  toToken: 'To token',
  token1: 'Token1',
  token1Amount: 'Token 1 amount',
  token2: 'Token2',
  token2Amount: 'Token 2 amount',
  token: 'Token',
  tokenId: 'Token ID',
  tokenUri: 'Token URI',
  total: 'Total',
  totalSupply: 'Total supply',
  traitType: 'Trait type',
  transfer: 'Transfer',
  type: 'Type',
  value: 'Value',
  wallet: 'Wallet',
  walletAddress: 'Wallet address',
  youtubeUrl: 'YouTube URL',
};

export default labels;
