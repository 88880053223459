import { ROUTING } from '@/constants';
import { loadPage } from '../index';

const NotFoundPage = loadPage('NotFoundPage');

const notFoundRoute = {
  path: ROUTING.PATH.NOT_FOUND,
  name: ROUTING.NAME.NOT_FOUND,
  component: NotFoundPage,
  meta: {
    auth: ROUTING.USER_ROLE.ANY,
    title: ROUTING.TITLE.NOT_FOUND,
  },
};

export default notFoundRoute;
