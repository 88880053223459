import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const TosPage = loadPage('tos/TosPage');

const tosRoute = {
  path: ROUTING.PATH.TOS,
  name: ROUTING.NAME.TOS,
  component: TosPage,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.TOS,
  },
};

export default tosRoute;
