<template>
  <v-sheet>
    <v-alert border="left" outlined :type="kind">
      <template>
        <div v-text="$t('alerts.dataNotAvailable')" />
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span v-text="$t('titles.moreInfo')" />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <span class="font-weight-bold" v-text="$t('labels.message')" />
                <span class="ml-2" v-text="message" />
              </div>
              <div>
                <span class="font-weight-bold" v-text="$t('labels.code')" />
                <span class="ml-2" v-text="code" />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-alert>
  </v-sheet>
</template>

<script>
import { errorHandler } from '@/utils';

export default {
  name: 'BaseErrorMessage',
  props: {
    error: {
      type: [Error, Object],
      required: true,
      note: 'The error to display',
    },
    kind: {
      type: String,
      default: 'error',
      note: 'The alert type',
    },
  },
  computed: {
    message() {
      return this.processedError &&
        this.processedError.data &&
        this.processedError.data.message
        ? this.processedError.data.message
        : this.$t('msgs.noMessage');
    },
    code() {
      return this.processedError && this.processedError.status
        ? this.processedError.status
        : this.$t('msgs.noCode');
    },
    processedError() {
      return errorHandler.process({
        error: this.error,
        msgUnansweredRequest: this.$t('msgs.unansweredRequest'),
        msgTriggeredAnError: this.$t('msgs.triggeredAnError'),
      });
    },
  },
};
</script>
