const ACCOUNTS_MAINNET = Object.freeze([
  {
    address: 'did:com:13t4xzaenxnvtarwl23wqcl92due49cn0m3k9jy',
    label: 'Vault Address Edelivery',
    owner: 'Commercio.network SPA',
    init_tx: '',
  },
  {
    address: 'did:com:1s9lfa3a2sgry86k288yqcsrh5wvflgtn9zwh0t',
    label: 'EX-VENDITA - ora LBR',
    owner: 'Commercio.network SPA',
    init_tx: '',
  },
  {
    address: 'did:com:1jyhpltwrnlccfs3puy95suvh8xlep38rckvn99',
    label: 'Davide C.',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rkrnz57sf0474m6pyx453aghcyxpra3spm5sdg',
    label: 'Federico B',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cklr42dmhmkzmxt6ldcdvg0zvv4ue57ulhtkhh',
    label: 'VISMI',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1adcxt5tjhud3gknw6jljllusk0w0q6l57v2y6d',
    label: 'Noder',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19esplhzvfxrrw7qcl56h0tu45ns948w354udjn',
    label: 'Arcobaleno',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1vs4v772rwrfa04vmsq5atc2awqj4pvxaeslzs5',
    label: 'MGS',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15p4m9nm2ngth3rleu6nshmrvq3l476zd0qhdza',
    label: '',
    owner: 'CONTIN',
    init_tx: '',
  },
  {
    address: 'did:com:1vjmdsn9enwz5jmucr0q5xe5yr27mlnzmv2nqzx',
    label: 'AGH',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13y3jud5sn82d0yl7ud2q0r5yf7ughtd9ghj5vq',
    label: 'Ciampelli',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12m0cajektt2uf8xtgkv5c7cdxru2ra2nesy7dz',
    label: 'HLV',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13lsgrexen462t4c3fdc9va2fvlhaehf95t40am',
    label: 'Tradenet Services srl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1202rqeepqsy84e0wfqxfu0q3mufahh9zdxwtfn',
    label: 'Federico Faliva',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:194tgyd92kenwfvc9tha6vkew2eaxrpl0sykgpv',
    label: 'Gianluca Lanza',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:126r30qjgqlumx49rkgvd8n48nnze8jwjn56n7e',
    label: 'Federico B',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gdcxa02g5l3cm0mgqfsz3ju42jyur82z3cx45p',
    label: 'Commercio - BONO',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1sy5xetx8e02583jaxwhdysm9qngeg4387yn2mj',
    label: 'Jadochain',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:159447efeqs3dwfhnlxwsrc8w6awqnxvz4vkxwh',
    label: 'Filippo Moore',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1c86asmft6f2c4d3l9fc5h9vuasdrphp9wsgw8w',
    label: 'Enrico Checchin',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xv6h9zcpsj06ztme6fttxh83zeedrtdvu0fcmn',
    label: 'Roy reale ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q8mkesv6kcyr8ft69mvtmy6lxzfvn5y6ywhgh9',
    label: 'Blockit',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jywl63g45qahysytsylmsalnlsa4djlvtmvjgh',
    label: 'Raffaele Viola',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zkzy52fuewa2j90ndgtpsqtfnk7gmfnvvfg4ln',
    label: 'Maurizio malaman',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14mj44lkffgx4vglpze5x3kfyrur8gxayduzyce',
    label: '',
    owner: '',
    init_tx: '?',
  },
  {
    address: 'did:com:1v7fqayg6r8vxf56ydp9ecyawysrcfp3c23nps0',
    label: 'Bortoletto Alberto',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1k4ag3n2lcllmspmf799w404qa3lw0tkkqshw27',
    label: 'La Rocca',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xuw233xtxdk0rzrtc729683c6zjattddtlglzu',
    label: 'Enrico Mazzocco',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10mle4plg6q5jexgelzkllecel5trd279cl7y30',
    label: 'APConsultingSrl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:195jfy8zzk35frg44zf8xzm2j7hnhnp9wuyaszv',
    label: 'Fioravanti Andrea',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10c7pgucusnp74ccvtzxwq6v96fkzvy05sfl6k4',
    label: 'Marco ruaro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rtk7v8rfn9l4nn726xzvrk8wxpq50wx7hu7nwh',
    label: 'Enrico Giacomelli',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1s9yq6vlz9x0mdfruuzly9kmmzl9j5cjc8f6nh2',
    label: 'Massimo Comin',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zjy4k9l5h48q456uswuqdmahcshju8kt68c3hd',
    label: 'I.C.A. Capital srl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tjhzkamsfwuej0keh87kznatw5fmdct8pyz8lm',
    label: 'REcperl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ev582mr2pjcpqq4v3m0v46mc4dse9jhfzgrxrf',
    label: 'HSN east',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1x3v9g0xkum9aqsnx4lxnc2rx4n4uaeh6rad35h',
    label: 'Talamora',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m6frjkvwv68lxvm7s7nuw6zsdd0g9ltv6q3w0u',
    label: 'NamirialSPA',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pjm0fq8d7wma5erexur4m98yyzaeq2sjf9sx9r',
    label: 'Bortoletto ale',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gfcy94ze6lumxyygd5d382mplzecny6hamyjvc',
    label: '2C-Solution-SRL',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1c44sw6srlnzc0njnqwckcj8hy37kj2x5xwxtf5',
    label: 'Hestro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1maypz2u8y82qe3c43d6muznuewpt99qrrkr8j4',
    label: 'michele morbiato',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13xu2z86rju9xn8qmqu82lsd8wey2764j8sxh0f',
    label: 'Commercio - ADAM',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:180x7smktncjh6mwl9en3zk7l7asrm0x06tkle7',
    label: 'Commercio - EDGE',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1342c5kfh83j85j5h7ey8h5skw9udu5n0e4yyhm',
    label: '4chain',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ch5wpeux4rnsmnxxj2fazhpkvnpt4qhdsk6ags',
    label: 'Commercio - LARRY',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1jkqs93dk9c04pd2mphp9k0e6n0se9eed2hww9e',
    label: 'The Skill edge',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ftk7nychaaufk8nn4p2dhf2dcajw29dp85lw47',
    label: 'MAS',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:109fup66yms0e559l54tjaawz0rsj2gxvqzlr9z',
    label: 'TSC',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fd2q96c3rtlc0d02ges4taa7zkqv7fhxk96tkn',
    label: 'Fioravanti Paolo',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1g954xlqpjv96nrl75u2ulqjaw3juchxzuzxy7c',
    label: 'Cavalcante',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10zsh8nmuuat805nh972el046sstesz8daveauy',
    label: '2WS',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zeuwtrpfmpdgvzm53xlqjpyj95pl2h5fxfkzrn',
    label: 'eGlue',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jrurlx8snj7ls9vx4lkycvzzh33cjeyt4nev5j',
    label: 'infominds',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15jpk7u8hjsqkfmng5f57q9jt3se40es6qfh4kg',
    label: 'Blue.it',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16h05vp73t4dnrunq02u9tplfw9a29hwzv649ua',
    label: 'noidue ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13qmegexkc0lqv8m0p3vf7mft6er2v3pdu2w3y6',
    label: 'Europa sw',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1c0dzxu5xfuw5ypxuuf0hkxxvt9ct49sp0up76k',
    label: 'Spazio professionista',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ldh6kqdw853ej920pehs76d4quc9zrsx2lll07',
    label: 'C.P. HOLDING Srl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1vux58aq5yy4xt2sqreu6nf63yt33lgt6ckzq0a',
    label: 'Nym',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17qah7dqdf6rk7k0sn6u20rx6udahvp2mkw9ujy',
    label: 'Corvallis ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xa9j9nwgk3rg000uxn9tttg25xc6y4vrvwmkcn',
    label: 'ASS Treglia',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18f7um5lxwqnjhksngw5m8c9qk6eq3cc6r2nxeq',
    label: 'Tendermint',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ngc63em3wlwrnkk5ktppunastg88l7ct0glpm4',
    label: 'Faber ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1n6j8cymcd2upaynlt74d6wqz3mdkc982zlge37',
    label: 'Satira ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hf490ssj8z79xc39f0g9mkuknfhgseh8eetdul',
    label: 'CPNE',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1a0uk7nxf20gzqcumqaylr5fmg8nxdzhyumvhng',
    label: 'Geablock22Srl ',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:182rfxy9qrcpqnzatw6meaqkcq54e89ztp2wnxv',
    label: '2meet2biz',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10vpmwkwuud2alyx9cwdtzjg4nd8e22lm6kql3t',
    label: 'WeChain',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ljq5ur2fcgnx4yaxvvte06qhslvqg0y5srtd9j',
    label: 'PFM',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ca24fxjad5xx44994dqtvzkcpz40jpxhytx4t4',
    label: 'Freedom',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zhl0de5h28ra6m5uy4cryqldxtj2gw4nnwjn0e',
    label: 'MRM',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1upmuv6vu9avrqe43dapq04ehcuu05ul8yyuhg5',
    label: 'ABB chain',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:169ays5eajpq3n42lysc2e4g8ypwd9z77hfgx9n',
    label: 'Nextid edge',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ptlu99ej6ptyz834wfa2fvna5l6uum4nvwsjeu',
    label: 'Geneus',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tt47usnr6qguaf5vn7zms9cq5mzvfxzrs0cg6z',
    label: 'RECPERL-AG',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dadm9krr7rlulk0heh2k0u96u8vva8kg6u33rn',
    label: 'Summania',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1cajw9x96j4dcz9cnr4e2hxd2ryd3c9ky7lzg0k',
    label: 'FAsst',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jfvjspan0w32k2f6hkmfj0qy0ya2w9vkk5wy3m',
    label: '',
    owner: 'CONTIN',
    init_tx: '',
  },
  {
    address: 'did:com:1743wetdlwzjqtzz8cjd54dmu6mqwfj2ephd48j',
    label: 'DDocuments',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ml9dl2fepcp95l6pkhgzpck9vf790xr954zxht',
    label: 'HSN west',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zuq2uch23m3kmvsmmlmagk3np634chrze0cde7',
    label: 'GAMEVISION-SRL',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gffnsr4gqy246ye6gwevwqd56swymt46vvvluw',
    label: 'Riv Capital',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wlaeukqz2vn822cc7qqqscp00zner8q7tyym8y',
    label: 'Gameshop srl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zz7v326hmtzqf6y35at5z5aya9l3ktmsq2wy2s',
    label: '4solid',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19xnrnmp5pn7mpg6lusmtx5ezzl25n5st824rxp',
    label: 'My credit service ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17096a2h7t9ywsx2vxrqnvqkp9ac6fy838s0pxk',
    label: 'Talin - ABR',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xgj64949fvmqxckaafrs8qnnh2srn4lkmajx00',
    label: 'AIR grading ',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yphjl4yj5r5rv3hckq5ujnj38y8lgywhssq96s',
    label: 'Future-Chain2022',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:199zvk2lfgwurfehuczz2whteg0gjspvq68mkw4',
    label: 'Altea Federation',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:102j27e664cwk0fu7k6mqred5z7j5fnjke5sg9e',
    label: '',
    owner: '',
    init_tx: '?',
  },
  {
    address: 'did:com:1dr7et33lpydww7u03vsezqdj4csv0ehaw0rme5',
    label: 'Paolo Bolla',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1mru95376m0ke6hplxvfy9x953vsulsztdzdkh7',
    label: 'Avioparts',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ljfcdla9x7642gthjq6ct2qx5hv5hydnvsdacg',
    label: 'Var group',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1umh9qvjmnlgvq9n7zpxvcveg6rnh68l4u6j0wm',
    label: 'Alessandro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yk2k43qprn5ggvdacda03n8mg6jp5j3dg2xcr5',
    label: 'Yarix',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zcvjckasuqgnk6mwf5yu25y0lvc60yrf6ppvtg',
    label: 'Alberto',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hu693n3czjmrjzp47xanz5j8a450zdw3c302x6',
    label: 'Alessio',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1csgk83ssrdauxlkhm2s97w4r9vgvc6mm4ysct7',
    label: 'Egidio',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1r86t9uznshkrlchf35xnpk8y7r5ys5hh6uehkg',
    label: 'Claudio Canova',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wf8p5ekeechzyw25kaaqeymdmff4stn3tmmnz8',
    label: 'Lino Lunardi',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14mjsgya0yn60fc7wu0u4rwsxe6csuh0p9w0wyj',
    label: 'AGH srl',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15qzjqd9hx30muud4ygv3c4zrs3pyymt7qm7xrz',
    label: 'Pier Giorgio Tiele',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1mq88j9kqm76qtzpxvachrv2gm45e6s4kh2e7kl',
    label: 'Silvia Rachela',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1nasjzr74yy256ftw8tzvnq0j9zrxlr5s0psa2l',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1z0znygkcsyjkqapmcyrcgtqcmnpn204d9pdga4',
    label: 'Marco snichelotto',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1u8masxrrd78f37r77u8sczkf5r92frp6cs04mx',
    label: 'LISBONA - WALLET',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1fkg9n68ww7yrcr69sq58830nqqakywah909vk0',
    label: 'Tradenet Services srl',
    owner: 'TRADENET - Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:14vuqkefx98n03kzlnsu4llc0yggphyy96gqna0',
    label: 'Resistere',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rsyglnhpg7q6hvz3422wm63tehtkx5xa2uwp3j',
    label: 'WeCanConsultingSpa',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:13hjffede02udjelt2z3fg32xd3yjv2m0u74na3',
    label: '4solid',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1n73k0ll5c4rkuvpxqreay60tmcuyayg625j8sz',
    label: 'Mario Veltini',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1v2mdfjtacwtm2qywzpchz5jhckycyznjx8d2v9',
    label: 'Fausto Spoto',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tjgxwd6te07fp09qs2gsa7hkg9ucfmmnlht0ce',
    label: 'Luca Bazzurro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l7589w6mshkre3a7afrc5srqgdq566385n0tar',
    label: 'Studio Polidoro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:195a3dlxfamczpevhngh8uh2tdd445jt6yw565k',
    label: 'Slowcoaching',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m5c45lq30lzwxp73stt82mlukg8yvzekjcukrj',
    label: 'NFTT',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:145rwxrvnfad73uwhxhakmaqlrsnvpn3wl80z2j',
    label: 'Vismi',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1yqaunv0j33r462w43959lhr67fznxcat9z5lxn',
    label: 'Avril14',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m7xq0hvjkyulzpkvmvgswx8cxwyayxwu9c24n2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:156h2whaprn8zc0ryz62wpprpgklxykm8ump7rq',
    label: 'chain4Energy',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wcx60jyznjrpjr5wrepvwpv5vmyjw82u65etfl',
    label: 'NSSP - Daniele amico prai',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1ggukr9lerccxlpmd7xm69s0v3u8jneurqzdjv7',
    label: 'HLV Srl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1052qyqx8c6qvx8njqnzj5e5xt0pcqx7q7svhh9',
    label: 'Silvia Greselin',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14ff28mf66adwmxt3evzqz5609kua8awq77tr5v',
    label: 'Donatella rocco',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1aazh96k4mxqwmjldvzymhcn8u0ryftrwp6l9mn',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1md4660dynea76md62v4thp60uag26c5lmdvqr4',
    label: 'Angela valmorbida',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q88kxlvunjshhcexf9jhrfmcdvf9xq7dtrcjmp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1r2r8g9nawwwp8fuyj0cyveeumzj8ep9cmy6edz',
    label: 'LAFIUMEMOBILI',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:16jt95nd9tatlgqrhtas7xcy2l5gtz83a9xwt5q',
    label: 'Arcobaleno',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1vpq3um673fa4mtmfkj4qgf9za6enu9z8h3q2m9',
    label: 'AGH',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1hvf37vgunvnvtlw259g6n3xx3qgnphk936qsmx',
    label: 'MGS ',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1dvmc0q6x4x9u749hzrj6f2v2ft229j9s278nny',
    label: 'Noder-Bc1-Validator1',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1t2zade4eye2wvf65wtqs5ekvtt65ytryfdgdnn',
    label: 'StakeLab ',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1g7jxvqr2uqj8f7lz7nuwttm07vll0dkcxgflfs',
    label: 'Borghesan',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:183yl8g82asz8hawejuzp49ut9pq2lf0atq6saj',
    label: 'Sara Noggler',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19hue3g6lys95adph57lallk9tz2axnmvwzlmy4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:156y48fvvtkscdefvfw0mnyrlqsuc98t0lzwl5a',
    label: 'FLAMINGO',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:12ja8ulvdunj7kd8lelhvctgs0xzrlyj8tz3d5a',
    label: 'Luigi Tommasini',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ltrvw70gtxfqvzh6pyqnqwfpjw6vj6qy7nnuzs',
    label: 'JMF',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1uvkgv2n95vw4a623af6l6df6c9ngajumgklnn3',
    label: 'Ransen',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:166cctxwuuvqz7g02xm9hpty0l3ampjzdq7797w',
    label: 'Excalibur',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1chqrt0h7sf20mqayhgrat87zst4yvwkk65ulpy',
    label: 'Equalizza',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17gep7hem6dmh2qy6c03ztgfv4u7gm0l23y9734',
    label: 'Serenity',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cqlws43v30d9q4ml7es5a84grhn3eqgr950446',
    label: 'Austro',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12fuwcft9kpsy6gfxxwy5le8pj34lq2hwdmdtz4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1lgkv54jel6plmn4dwp57ylqxge0v6pf2x76n3a',
    label: 'MATIFCHAIN',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17gweum4lfkehaysl6ev64d2vs7vclw7arnsp3a',
    label: 'Nextidustry',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hl8sgm5ku888untr73qzmwj6sg7y9lxdtcrz9e',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1h2sthnud45t9q40e5ae54u2x7eglxwgxfqecmk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xr9pxqae9luv4yf2smdy3hp60cjlmqk9za9m4t',
    label: 'Sara N',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t0zsvt8xpghwxyejupr7v63r7pr8axm3hsg06w',
    label: 'NamirialSPA',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:12yqgkd5tjdfz7ccqlczecyd6zxr5swgdjhmp8t',
    label: 'Imperator',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1cf7k8dnhevj0pqrj4tal2p377s427e645nt0cc',
    label: 'Davide Ceccucci',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1a6kd5v8cyu2hxmgfdj3txk2y4cj07dqa840chw',
    label: 'cav cav',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rn99ye7d0lfpd6ucr7n07qndfdmfpcvwgdkld8',
    label: 'Gulliver',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1mked7ag9y226p0nl7yrmkl225ffjr3dpeffp8t',
    label: 'LG Luca goldin',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ne73kvgfttu9fh2n5pvhpn944efs8tkv3grkj2',
    label: 'NYM',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:13da9muayvuk8vzcsmdfzx24265mtmk7xwmf2sn',
    label: '2WS',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:197p2t6cueylr3kfhs7u5jfxdmpryw4zxq0pvqj',
    label: 'Vic Kaul',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15a0fh4w52d0e6ffrnls52rujl5ydcaq5wd4zcw',
    label: 'Pierluigi scotolati',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19srx4qj6u6z3qrtcpjr4hg4j2glvdp9nma2vyc',
    label: 'INFOMINDS-V1',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1035rwjufd2rlefusqc3jdtrsn29lfmt5y8c5j7',
    label: 'Technology Skill Chain srl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1aaq05r5vuk3qtp2t95p5r4g7mgqnjmr9s7u5zv',
    label: 'SPAZIOPROFESSIONISTA',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:13h304prdh4vxpn6d8gw544krvppvgms0n5t055',
    label: 'packchain.network',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1u0rn6dx43d0ahk3ynfx5cwtrr6hperj5f5d0pp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qs3e48uqmn84ev6z9wf4cmt0kt5ml60a6750lt',
    label: '2C-Solution-SRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1s0kurwmzsqn2esdkpclyl7klxqzwx99f0f950n',
    label: '4ChainSRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:12efg5828jn22ulpxq0h24ffcytvezyydsrvq4f',
    label: 'Advancing Software Solutions',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1clenl5dmp42yzgkq9jwgt6qemd4v047z7juy6z',
    label: 'blockit-bc1-validator1',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1gryag5mgcfkcmvjf4c6rtt6yuhy270uyqpwql2',
    label: 'Europa Software Srl',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:10mwdtkduqve243hfhygqy55p9px3tt64rcuvnu',
    label: 'La Rocca e Associati spa',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1gl2xwh8ru8fa4ucheuxcp8g7cg9dsfdtpsy5ec',
    label: 'eGlue-V3',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1r2zjk82gdnntd47fjmwcpymhw6uk4xcuc0qrm5',
    label: 'Bluit',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1qwr89tk063mj8jkzhnzxw224gdjvfsclpnd7gu',
    label: 'I.C.A. Capital Srl',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1735g6y4gj2u224apv3ymtq55ecvjrt2sflv647',
    label: 'StudioBortoletto',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1mefeazk58l55f4ar245rkpeuurxnvhc0krnguj',
    label: 'SB PAGHE SRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1k58vklqh8kv9jvv3r8mejaxj7xcwd5jjz8r9cu',
    label: '2meet2biz.com',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1vn629q9z4k066l0t3hw0emhczzss55emu46evg',
    label: 'Noidue Srl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:15luyv7a9hzufx95gya63agfuc0mwkkestpeyth',
    label: 'FABER_NVAL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1k78p5gvga70urv2ts33h4vcykyhpatqywnwpj8',
    label: 'CPHoldingSrl ',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:19hgtngq0vz7vrlk78cwcw4x6cxxgf0gw0urfka',
    label: 'WeChain srl',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1qxr6q6mrugu67g24cnynlwcxjnn5r3f46u757j',
    label: 'Talamora',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1xkraz78m9ywlya9uzc6kzfsd4yprre9zfcwckr',
    label: 'The Skill Edge',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1kunk720k34r83vdy5yr9ah99wpnanl8uxqscyr',
    label: 'SatiraHoldingSrl ',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1m8jwysc7ww26u6ses8ej6hytft7ry9a8ly57ng',
    label: 'ABB CHAIN',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1qjl3dqsths5g4zahtpjgfzxfq39qxwc0vk0tfc',
    label: 'CPNE',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1fwxs97flemranzl443ddjt3fa6he4f4t3cqyyj',
    label: 'Corvallis S.r.l.',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1wwemw2ne8qutjyyl5kskmya0yh256qp4k3d4zt',
    label: 'CRYSTAL TEAM',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1mcl8kmts05af3t62xa9tyqkzuh54jtk5peknm0',
    label: 'geneus.network',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1t9vwjnqwt3zy24e5snhtndltwfmqcyn0uxj483',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hp9vgtz6fj9u0eyly760d3tj2srcc66pp6ps2h',
    label: 'FreedomSrl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1nq83778d9sj9j023e6ek2wfpyns0g9zecyajje',
    label: 'MRM',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1pu05aup5uh3hm246a6nu30yja2c4usm36wrkyq',
    label: 'Nexid Edge srl',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1a8kqenwsgsy2pql7fqdqxhyccaxg30mkpazn0r',
    label: 'HNT-EAST-K',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1r8qmv7jgxsnjafh7yhtpzcaf47a54g9hmmazny',
    label: 'RECPERL-AG',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1e39sa7q4slwktlmkd80cj2ysasv2wqfnxqxvqs',
    label: 'MAS Management Network',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1m3prf0kj2ag2tw92qgh7rxhvdatz3jd64y3xqe',
    label: 'Non Fungible Think Tank',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1ryvxa97djtk6a79hr5qs5wznxrc9uarlx54zhf',
    label: 'APConsultingSrl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1fe0enng66w69t7m00n7trx2ej78qcafxdkcny5',
    label: 'SLOWCOACHING',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1y8k5td4knyp2z2sh89cgjkq5mwtweu7gupjgvr',
    label: 'Avioparts.network',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1srvgz9khe97ja7tgfq8f9qesfn9587a87dejv7',
    label: 'Ransen SA',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:173makdrhr2z6pjcrm7rsqy6hjvujuvg96g4e9j',
    label: 'JMF',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1wspufm0ap66ansyhshvv66xrxge7dxvdtdhe0n',
    label: 'Serenity staking',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1673q94amw8vnhvrtxyujf7eg6s7nh2evw46p6r',
    label: 'Excalibur Swiss',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1mky6seqc2al5rpnc9uw8xtx8mtts8xggc4xskc',
    label: 'Equaliza Italia',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:17e90njmeh45jf9re0cyfvtl4pmg3nwgxf0ucm0',
    label: 'Austro Staking',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1nnxcedwkfsurq58r4ax8e3zgt78kspjykcd93d',
    label: 'DDocuments',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:14qrh45e59v9w3gdxtsqluswfe59vqfn0ewcdvl',
    label: 'HNT-EAST-DO',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1hru0xvsha7k4zcwgjza3jlgk7mkf4ul32c8fkt',
    label: 'FAAST',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1p5w4m22akjd3y9n9qe9zzuyd305sfqw7p7wh9h',
    label: 'Future-Chain2022',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1trzvxs4gm2zvjxvdfr6xg6jgzcqjyrwg0ytelm',
    label: 'GAMEVISION-SRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1hedpqknk2kt82ewyatqdp3e7cqlsuunhmd79g4',
    label: 'RIV CAPITAL SCSp',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1jrnt7u5l355hcc2jh0rdk38xaa8p0m6anspjpl',
    label: 'Gameshop srl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1nm50zycnm9yf33rv8n6lpks24usxzahkq0je85',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q7v0wtq3t6va2th4s9kl0nrws8chatchy3ks5j',
    label: 'Metaskills.Network',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1c7ld3xzzpv8uwmcuud6vyyg2nnmk62mnrm4wj3',
    label: 'WhiteChain',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1p5kqe7nluq03aqpxa9jvav8yh0vq5xeyyq02la',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14090ggs0vgrx6ex2rsgman6rldr60r52cz92nq',
    label: 'Hestro Nodo',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1wfe499n52scdwkcvz386st7v3pxcf5vcd57kq4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m4vxhxy22m2mw07zszpt836zy336lsh3mle5ls',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jn5lu3ccq3tflpmqxar60hzu90epfxz9e5560e',
    label: 'MATIFCHAIN',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:10l9etgh4gukpwne6yupv0s4vprpjekufx0kmum',
    label: 'NextIndustry srl',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1xq366z9wpx2uzvr6d5e5g89hl3902km252n4nl',
    label: 'Enrico T',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:187arzr6q004ul7pfkmj8p7qzdfpgmkf72dl4qg',
    label: '4solid',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1s8umh2st9cgv3lq8pzhdmarh48lnua2u0fu5ek',
    label: 'CAV CAV Inc',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1wrjq3mj9x6vmqh37lyzcsnrpce0qkepxwnskdr',
    label: 'MyCreditService',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:16k8zjdndqctsnvd3zwfr2ztemxvd2w27mrl7wm',
    label: 'Commercio -TUMBLER',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ft3ggfazm9yakmhl79r0qukgufesadkw3xpsmx',
    label: 'Tradenet- TSP ',
    owner: 'in genesis',
    init_tx: '',
  },
  {
    address: 'did:com:1m3qnpktl46az5japmxh394jxe9qsarfdhslqrm',
    label: 'Commercio - ORACLE',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1mj9h87yqjel0fsvkq55v345kxk0n09krtfvtyx',
    label: 'Commercio - GOVERNAMENT',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l78was7d4fe2zy8dl4ngch9sdjzyqvlpnpms4w',
    label: 'LG',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:1eragpmy0gdgpyutd7eysvp73z9z7lrcpfc6e0t',
    label: 'GulliverSrl ',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:12hs6g8rxg45ptq76jwjjf8qeacnest59wexjhv',
    label: 'Altea Federation',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1mcuqmmxecvmk27dplgvphn8wmqr48zw20gudgy',
    label: 'AIGRADING-SRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1t9f6le5ashmf65hsy96xnj5ukhpmsgg5an30ge',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1u6s4u9qe6z0962zyyf76xenm0ch90ql0vmy036',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1x4hpem28uhrlh2sdvf3a2f5rw56jtvsgmjz5yp',
    label: 'TSP Commercio.app',
    owner: 'Commercio.network SPA',
    init_tx: '',
  },
  {
    address: 'did:com:1ah07mm9ahypyuuta0q8wdppnmvadnzgvja4r4e',
    label: 'Chain4Energy',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1935u9scd24438xxrlkef2s79hu3r3pvm76m0pt',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1k2437dz9h2kc8tchc58h0punfne9sxf59e7c5p',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ugl0j3kq3scus358rg0z9d74te2ymf9w7pfrar',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cjnpack2jqngdhj9cap23h4n3dmxcvqswgyrlc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gyagzzqz34fhywee6z4aenkjkwvl5w97ja3cml',
    label: 'Avril 14th',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:1f6593sndctxkmeq6ezv3ws8nshpq0netgvs6kh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1j0k4em2nlkvst56wf00tk8987m8crva6za49vr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17kaax93l47pvrjtmeyajpguawqtzxq8g7ksv8g',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1edyn6epk6yl8d3c803fvqhvyk59jxs0n5levem',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m3fw4fxxlhr4vhuxk3zwe27vt027eu2wltxa0u',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m77fd8c6wahykxf4mlukaqe997hj7y9f8l7n4z',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jkf0kmeyefvyzpwf56m7sne2000ay53r6upttu',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1w06sk4v2w5dryajw3gck4clm0p0g2qcwv6pnp4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14wxshfzmaysphmf6jt4tjmz4cv026rkv4f0usj',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pv2q8xr6rlaggf3egzktssmw0vv208lky6ylf7',
    label: 'SETTETRENTA-&-DINTORNI-SRL',
    owner: 'Valoper',
    init_tx: '',
  },
  {
    address: 'did:com:133mkkv6zc63hnuskerkfkg32xg33k7uqcg6xvz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hcnk3f9au0nwuzgv8nwxafvf3p4t6x839xpdw8',
    label: 'ABC Srl ',
    owner: 'TRADENET',
    init_tx: '',
  },
  {
    address: 'did:com:12p3lda2t64hanwfnreptzf6evh3pf95fnctena',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13y6mrvkj3255mscz9q6gftz9xk73whnrn7jn2x',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15klvzzcq60452wnxpm05y238dlswmxt5ejlnef',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15ygp5lf92xytlwt5ad0xqmgcu59gcrprypvepa',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15ysdttn4zsh45uzzxapeeqpzq0y5gxj59ks3ej',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16gzpz5p30y36r0hx8h7a29k4e2s04v8hdr8jm8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16j6058tsglsl7z9cpu2f2hmg67w0p0m2jrk7lw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16km9pydm7s0ecetkeyj87sth50ahzu7nc27u82',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16sd05dpzzs60qjpwyk2l3tnflsl2uj0579j306',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1873jrwhp96a66s49q4tr2wzkqxc4l7e8z9hmf7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18rqyutezrf8mf8fsny2ljg29wfnl4zkx2asu0v',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18slxr39kes620eadda4u2d3t8pm0qjacqm7l2n',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:192ehjgvypys95zql48wlq2mjwfthhk0j7dwup3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:193v2ky2veptt2n8t8nx8r3weeuwf98t6an3pvk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19gxj2tp0yljfxd6lc6nsy844rhkden6xphlec8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19hhpmutp87th9s74wl9d4s77yq0unm28w0nqsa',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cklq87252wyw2wxh9grzkgky0txjt05k5nwpyw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1d6vuj2xwt66j5rxu34r9sjrg3m45sqesugkdw6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dgq7uvaulvt5km9ynhxmd7eddfu6qu47ee49t9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fr2vklwdj7lcjlmxv72jm7y7dlelr2sdt6rqyw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jgqk83474cz7vl466589h8p55z3e58k007q35q',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1k5tu7238z4hmykrgxe0qfqryrgnnafgdg9w8vf',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l5s6rlvt9xu4ky55p76t97dyuds8ypg3rnl9np',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ld9el0wvrt5judaaahq6ln27hayzq7tkje9g3h',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1lsa4kjv3gypp8k2tna7q6g49n6qny5qe80262y',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1n44aql3ctnxfmpg26g4dvla40lpz9mvwkt62s5',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q3r7c3n9hne5y28ejhlxf9hr0ljuk9rmrsme5c',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qnycnv6f2ekas7jllx7cdvxtrf0u4kr08f6nm6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t2zfm3umc2mfxnx259rjvxsmqfua5v3flk54f8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1uuysnygtx5nd2a67m3wqq7gtkgpj2sp2v0ln7c',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1uw57zgkvfc8xseclse8ey0rv2rxdl7zq5yl8x6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xqsc4dremd85ghgmu3qd4s0ypdhw3mtexrlfw7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yfzf0jenskws8p8nkxcf9l57erm7qpcerskq6f',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10444hxkc4gdjw5tr8j3yvh9dvk3ljf025uanjq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10h86rnv9jmcumsy5fnjnhufse8hpm46qaj4h7c',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10js9qgq6u0ynh5fhw5weu6r0qe4ttksxpztgeg',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1250ch5uhcl32h4tt5ez7c74ffjpgukzlh900uh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12jmq8q2s9g2ektkfn4x2w3nmh20az2gkk5lnk2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12pa3nk5087wx09u2z7a5qjp0xrpa06lqxlacwp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12spv7g3m2a6msk26h3kq5u25klmunqz9npw7qy',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:133jw6mmp0tp6md0amxj55d0ayfjn0ky70zm4aa',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:139s3k7trey6r3504njylq0qwpsc3rqjf04ecqx',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13r9qf4trzhhyn08lyfe32g92mz22k084ttcycr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13yslvnl40me0jzz8ldtaptnd8e9wcjg3qldz6q',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:144yv4h3stqrjg2yntep4js64mjmgu37k8wsr74',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:145y4knxemwqz25vg5gyjsfey2hgzladn7kxzd3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1476jua0ncra0z0fdst4f2mh78qw8aev3nksgkz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14f3a0l9ca6elx6r6cu6v0lhyk3vyx8hew2lh8e',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14fr87pj4afp5q4rp6y3fyczg2vcm8y6usfrqyf',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14k2gudra2ugz9gq9nkunmtnnmsv7aejf2grlvx',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:157t8s8rem97z67xtpuat494z95ezeuyja9nvlj',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:158ajfgmujqyldzzh635s0cmx4xmmt260pxr76c',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15d59zde4xyufdf7pekwv9qat33krhh64qcf0y2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15grlzyf0qze2ge4qev2ee2ykgunh4hjp403mq9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15w4hcf54jk4x5n5xwsdz5gzg8f0p8wnndmsscy',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:15w7w4mn2e5fnzryc9pdzjfzhl7y33u573f0qkc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:164nn7zqac8ztfzuc0dl736hwmw3qd32j804t55',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16cfpcl4zdf38y85qxknsk6swqk7d3s8td7retf',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16nqp8l75ayrv8dvdq7gx0uw0t5ynzklaxn35mt',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16p9hseh640qsyd3ztmm5ckkng9hpmzrv7xaylm',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:16q787hvv20z8xhj0wnc0cw6vy4hwltty0faevz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:175sm2whwsnzukwfdp69szv405jgm09v9787ukr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:178ntsz9n2u7f8sspsu2ktqvqputdsgxu3zzw44',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17jl6y56s6rme5nsts22zxfufzfk3y083v2zq4c',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17rhmdzlv0zjuahw4mvpfhf3u5tuwyjhr4m06dr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18gq2nyjz0zhdqtvg9c84pyfcaj9tlyn4a0v8ph',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18ykrjkt7dxdpsr3k2f3qqj9m337f98f8e7muql',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19dpmcyz5yyw5eh34jxvrgywvaccrhc6wt4dgxe',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19k6nlxgs5ttug79w7u5ue7d900r0a36gpn3khs',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19kwnn059hhzl4wefwyhyehxywm52ttxrhfs2ed',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19rrrdpjflvjvhp84m42klpus8mwjx763f343hp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19rt9nf73rqrh2uwuc6q2a34jnfgauufv9mp08a',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1akfr0ut8saz0mhl8vc5xfrevcgkjp0xgtakquj',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1apjuvszaggzdlyyu6ggp4nfqzcg89lck2emkey',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1apvn622d3q8nptd6fyga7umvdphl5vseyr9lc4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ar4necwcatkdayjhpury0tw0fza2agkpuslsl7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1awyf7h6r7x2wjkf87artdsslkcgu35mma62lzr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1aznec5eapmjqj7uuldefdpa63073jxq0hwhr20',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cj3y8dr7aelkdm8sqryhkjqz780hskj5yqeem3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ckaj3thnly5djcyena8spgm7n62cu7wqje00lc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ckyn939naxw07mwwmtth505whspxrmgjdeq8y8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1crddc7t2zknmvqgj42qgxndhjwjgrrc9quun00',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1csuw6r4dqa3y0nynhcpadnzwjevnkkfsck3ppv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1d8d9sfusx0axsrg6zty3xt9l47tugsv2prt6xz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dh35t68n8jmwkkdvngpvuj6pgdzyl7jnjfqs5f',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dmcn5l0mtsqah3satawd0z7z6j4pc8w2slp7yg',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dqh0gxnqthdt8gyggfgph23mwa2pmthnmmktr2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dqstc2dx5xar8n5jezhhxnr4yq4w0t0wkd8s8u',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dwtqpq35sqqw95z2epyrh835vemjx47nzdmmvq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1e0uqqun4yqfadm2agja4h5zsfcguah9u5yz8pr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1e6m0w0hy8yu3ntly3czdvgs2z9dz4vta6r4e68',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1e9ktnjfzvxutwa4qxegg3k43pwh87ygke0rsnx',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1eflx5wg4c0l4r66q3z3gmgzjlgeju4p5xprh7n',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ekndlrzng9hrufsz6mkqsqw2erg0jjgu7wgzu2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1erfduuan94vyn3k4rjdrg6ucrndheyku2ksw5z',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1eydmut55gx8gk6nq4tmqwhvy7wca37vh5knukz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1f38agt8vru5vxvlmjr9z32wqvgc0tkp4qkqm3z',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1f5a2u27mwx70fw8w68nslagmyseptra7wpfe98',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1f5x4zedutne3te3ragvv4h3726nzdves952egr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1f6dj6yfh6l80cqm9w7wxcxw7hlm77meh9c06y5',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1f9qyrr0qcpl6yr8kfngsuwsnq6lcczct2ec3ep',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fex4uaj24gje7egpfahts8td33nc5s27zmqcwq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fljezsywa8ujk3k28hylhjs3fx9dt8ftfx3nv5',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fnpndfma2y5z9at6q2w6n24avtjzm3w6wx6jj2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fsrt5jcwv2ztyszxmjllexqkfzzgsz2s09s2zr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fssndjlfmf9whdgfdc4awh2czl7gvpfjak6lp2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fydyfnt7mx7hmgak9sd9p6ztpwy0gt95aymw5g',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gc074zqam7vtzca2e2xukrp9w4sdj8yhrc0txv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gp93vele5ylhs3kg3s6mkdpdeda3328aqrrjp9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gr580yye0cjzwhja2rytgffly0cpa848me9n95',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1grqlaa7xljpj7dg282e2qfxfs5dskjpslc6ha0',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1h07e30t8r429wwjjmg02yqczr5m4smwt7qt2st',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hal0nzp9hvnwu0lc0pyxsurt2cv4ggf5vu8azp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hddeedyumxmf43klzakf9ur6lmaywpyjjj06jz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hennv4agam84lysmp0eynnqdff6m4axrmna7vr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hfysq5f2dykt82dlja0mzse8pjnm2ygh5ngqkh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hx6nh9wkc3rs066skgcax6aa2vdgewpxydadj4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1j29985nj6r7vlkhuwyxh7cwz79aq74xy0rw3a7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jrxf8r904vdgqgrfdlhh05vp74eshulkkk3agm',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jszn5pvgz5whcr6ra4nvd8q7lmphyuxfwcql85',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1k3v6h4lgwmcczkd5p2jznfglqdlrxc29wwklwy',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1k8wpf043t56p5ypfwqw7ar6fxpghdmez7aslal',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1kkrjfjw403jqkjze2tjd0mez05qr6v95jgyxlu',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1kp2rdynhehhqq3krdu4h0xukeky8wc97sdhxq4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1kyex70h0gxl9wr5hne46mrxe4usja77v0gj2nx',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l2g2wejknesux6st5vamrc8e5ce6ykkf6knmmp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l6zglh8pvcrjtahsvds2qmfpn0hv83vn8f9cf3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1l9ch4flvut6xzsa2fmv2mrqc6tf4awkhnrv00d',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1lcx0vjf2upjr005v0av69q4h8gag6f3dsvrkht',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1lexa5va4aj2ucyutrq4l3qunpqs9kggmgpgpzs',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ljp5shz4dwv95pyry8gka7wkkwannr27l46fgw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m0hffdj9nf79c8emr4kwdd2tqwu0g7tfd8lx5v',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1m7u3z4sxdkd2vlygneskm5t5j48xvuvdd4d3ag',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1mn2uhp4rv55n5ppc2lmn8q7qd6ky5xuq0j42tl',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1nfpmrj56x4grttekvwl4why85hmg7nrz3d07qw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1nhalvrpkcg8qtwnltxp7rrkm2rgetvgqf4tjyk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1njdptl7sxyl0u0dmj02e3u6ythwh0ncdps0ngr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1np3rkheul7ytdu6vrmhwty773yy3vx67zhwccr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ns5t4e3l9y3me446ltjxvz9c0qclpypv8jumug',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1numl40rdt0x23mlc7ugdgws49jtfd7f8lxr3xy',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1p376yxw8rq2w40szxhjd9ftnm7yayjy7r3584r',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pra0ke6tdfahw77thtcq69tkcgqktkjkt3n7uk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pzhyv3e2ehknryk7jcyqw0naklu3ekcrym5rk2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q2qnkthzpy88u3k9lmzpz7he3cl9gksnga8zet',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1q6vezu8hycywt0wannjx42yqfz0ftlssflf27f',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qflkjm9lfkur8wrkt0tup8a4n7sl9vpx5cf939',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qkp785sc5rw6gaymmp3xn84zwtvt6j96ee7amt',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qsj60une2x8efx9v6ukcga8t3nv7554rxgltm0',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qyxynj8eutl2mmmssexhvvmqps302kt3tlqfee',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1r7y6uvx7hwhytfc0uqasttw0uchup40j52kg2l',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1r8p8dqlhztfughgsdjrql599mfw87v8jcpmncc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rvy80ucv5lx0wt3ydyeuj0lxscwskqy0vf5rgr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1rw39vn764d7pkugaq8qtf5je7c5kmvrvy73cqg',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1sa087z6gg0af8l2te3x6nfjhrkf77thsuc9930',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1sc3uuznmkn47fht0cupjkvlh0nqrwaa0yzdtdn',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1sfdmda03cfkwgy7ldswpldpa8dxd95h4t2sy3m',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t2xm7zuct23tlrv7jg87wsndud7x8fz2tuuzt7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t7p3hr7w442vwy952q4rfm2ruxnteuxednkzpp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t96u3rpuxzusy5ens0rx2gyh08ty7e8pul8zk4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1t97qucr02vyydqfqf5jkhrgj6yjklwqapc8w33',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tadpsqpfs3v6ena3augwvh6pafvr5zs332lfs2',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tam67wmwdqzaqh7fn93x2l7d0fy0awvqha8cyv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tkvujdm6x8d87vp7kqf3evm0w2yd3gzxgxlw8y',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tl4aeznqhgw96dry99xkpnap50p32kkawkedgt',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tmg4450c8tcsylzwvt7k8gl79xcsexpp332ru3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tnuj25w7mqk7nuljkyhzqd07gh7cspz03gjuuv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1u728e2y2pfmwymy3kf55c8rzdp0p3uz2ehhymq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ucym8wacn2c54salz0enxu7lazm3udpc0wdj4f',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ug0pc9ksucdqdt20yw8y0z0307t5faqsd5l20h',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1uw6x0mchwrtv9mwfe7zh3fukqgu5ydhrnqwzyj',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1va02dg0zwhdwatad3medlus8zaahum9e62ahnq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1va8tdz6dq2uemysjlnjs4s0mrqg7fjs0l4uwcl',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1vdvyg85lldxawv99hqse5ft90dv0z7dl0fa3ha',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1vqml60leead98ps8xyevg30fvxtft4054rm202',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1vs2l809vsrcdurx3s0vq8zcmjxgqsxcwrw200x',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1w0ph3e4jvqhpt6ldafc3ma6jcjx837js8hk2za',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1w3zymrrq94a5gspe4ucewgrga0lluq5f8qmst7',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1w5p8595mcpg52j509wj4k7te6crlqzlwkj2s5j',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1w74y0vd8lmus0t9da64fl2cldta6qcvaywqs38',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wal4t8e4v5durkw30ca60awdu59g57xrhfcwvq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wjwv8hhsvwck0mtkcpkuy5r35uzq8phc9c78vs',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wvc90ff9f2s7z8upcvmm2r2k6rzj23lzrrdsj9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1x40rzy7nyv7uh6nmwrewkay3gc24zml60etdfh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1x62dvqgqdq2c6k7tv6n03fx6u5r2leyssn5z52',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xe3fq6kh3t9jamawu2whzyqmwav5rkxk3cd73m',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xrkmdtq07e8h0s037ypetvhcggmunxl9tyqjpd',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xtncxdq2ax87fwrt884ateww2twzugza2fu0ud',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1y9vz00k08m8xaeuy5c9t09wtxp0qtxt9r4vskh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yegkn2u3vz3xm5kuc90w0823q708g334cj5658',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yg8n8jehc5hu6u46yax3pktjjmjh6zknhf66u8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yp3ltw4xl0v3vta3njc0lfwx3lznv84em77qyt',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yp95rmhf0tmxdp0c8uyj8lavsqexrd0wd533uv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yqc7xzeze4mhtyeyveh33ucy7x6ufwjnndr97m',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1z98u99w2stk25a75ns96pxczu78cthq6fqjzan',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zd608dn2fts9sq0uvn9dlvgm7866fx56sdjuk5',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1znkmtzy52uwac693m8rymw76pj0fdr0fa5eatr',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zp0kqq528xmjhf7s0axefvxr86d8vkmzdjgja4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zt6wvzh5jzsgl67pg3pacv6gar2c0xp2wj74hc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zuwswrasy3prcyw4k0n5t7ntlu8ek6adkmvnnk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zz7p34q5xs43vs0xrl84du5dd4lt3psc2546m3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:102ysyxgjxuzhspt29uwrgfq0xn4jmmgnvxc49g',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:130xk5qx2vrwfyadtrm66j3ht58u9clansump87',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:154n8xcyu36eaq33wfwfeytzdqvt8phlmjxkfhc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1h0sw4l7teu9aun2jryyqhclg63k2yredv6hpu6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jjyhnnkpzp89daekjwy9pmcw94vadcmlr43usq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1qqqyaz4fgzffxc2yu0pflhhrwa0dgndgkz5ay3',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1whrsvs6p5zsfz87ehy7jgvrpxg6dn3s00hhz0y',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xcqt8zj2vwh2mkqvyphvkrjh3j6twh43fvndj0',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zvv4h5stn3lc55k40u8a8x8en2nzu9299v59u6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1c0wcgx8vxgp7v845shn2cue36lhdgspxqcyuxz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gkedkcxf5e2qtdn4sd83z4lrtylmjfatvl6nje',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18lq50k2lv2vdsjpwtyuquve209p5ckgx4ca96p',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1s56kn7asxextmvk20v6uwl6f8te7n7eszvm4dm',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ke2h5tzpcsyak62ltaf45ca8ppzfuye6qasur6',
    label: 'Wallet OPERATOR-CONSORTIUM',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xaml5ja66ufgg4g8hruys0e0pmpvhpp7fm770z',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1smydnvtpns2n3np58gvs5e9h28c20mxnzzes2w',
    label: 'EX-LOCKED',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1y7pm9vmye3kzqh2v9yr09ew3xmw62vdfn6k04a',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1u7de89kgss6hzazx07fcusyhwdkgd8n86sgla4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:14aaudv72vlp334nv62k8lyjx58x78hzhzdczmh',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10er8nnffxn4auk2f9qkkl336fhshlw2nume7sp',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:10lj4mdsqd83yvx6xagwfsjufsj3wa87yjrwl8v',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12kx8399m85rx3770h2ypmm5hapyne3889cjrz9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:12zvfwz7gm7x4qh55mga5kg3rv8884rhsfv4f4k',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:138mavzm7zfner3j6uvpt7g39zv4j54v8q94kh6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13vfdd6l96za2rh2nf4s97kqhw2ela8w9wcckyk',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:146apfag84lega7580d2mta7406a49w3hkqnsxn',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:178sr4x03fzcsdzpre9ss88x0rswugv5t7m7h7y',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17xtw5mcpg4azwutehm088vtk537uh3dh4lhxaa',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:17zrzmjtxprye0jy4rh9hmwzgjvvhfeseu5ze2j',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:18ehmj4g8cs5mzexn7mjmd3p5mq2zgyymje2u23',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:19zesuc0ac9x0ue2twvhfz6qq2qxn5tmtadwj87',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1a53udazy8ayufvy0s434pfwjcedzqv34p8ewta',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1drd5h8h47lng7z096mw6utg6gv25tugeyehsmw',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1dwa66teyr8rc690ekrfewcj6z8h62pgx2rh8kv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1er3j6lmlyea7q2g33wu3g6lca2c4f26qmfkd22',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1fx2snn4fn9lxk27qqfnplayx0n8ytxrmpfs7us',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1g6fclmgfyqtf0eex0t80zcv06lhjn7jwnrleug',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gcsvpvgsayjyfard0sak7e5hgdt4hw9nstxzj0',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gfc3ehsnjuhzdea0q9zlcwwmp4fts88u8dqtrc',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gut5qe97qx0c0m4wzqa5jrl9qqqg469cncw9q9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1gvushpa74et8phy2xsvpvyy0mgr48dt6uhjujg',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1hwu2gxkkm4z96hfqwatu6hmzagu85a83qn22uv',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jgy5t5rc8axhnmt3jk6r6j77xzdtyvm2jjg67a',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jjca5jq8lnhvhuaf79vgfftqs5st65ceec7gn6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jpve9r6m5n2t5jxhyayahexg87cnyn75expcz6',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1jvp60t3s47kkpfu2qv4es5k5gvre2weq27pncl',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1kzg7cud2cj972g9y8a37zundzshuzcaa6qt86r',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1n5jxgg95z8lu7rua58fqhauwz8sksvmjv0msz9',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1nzse8u5hwug4eg4dtweu0vpm4txvurz9j4y23j',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pld99358p3nvg2unms82qsvv73yv27pdy7zqaq',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1py237er2h2jdgdpzggeqmat556u65fv6ql22ya',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1pze68q9wfwm5jnhmpul483j2ah75arnatrn5gj',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1sdyuzygy9ys8k4cp9a6mevm9f6rzxcq44q4pn8',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1sqee9mgjppau35ksvrsgca6ekygm40vcffmkpe',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1tz8rtfxvcvj3v9kgylg3l63htya5vcch43l5gf',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1uqm24vz82997zj2tlte7sgla57n00yvt4wrg4s',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1wrq0famy43w6ffcvmcfxw0wny59emmkx6asrk4',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1xu3gcsdsclwxtz3wpdyynn698z28dk7hvyvh7p',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1zgdvw7k5e90m4sq4vlxkudlh4d8s2gmv3sc4vz',
    label: '',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1yuhpfee47sszelwmpn4057k78qd5dxl8ewwxdm',
    label: 'Jadochain23Srl',
    owner: 'Valoper',
    init_tx: '',
  },
]);

const ACCOUNTS_DEVNET = Object.freeze([
  {
    address: 'did:com:12hzcdgapfh9eyamd092mz0gp6gvl2regvsn0zq',
    label: 'commercio-demo00',
    owner: 'demo00',
    init_tx: '',
  },
  {
    address: 'did:com:1mfddzjvr8vpeqdtm6fuay2nvnecuk9qa8usqq5',
    label: '',
    owner: 'demo01',
    init_tx: '',
  },
  {
    address: 'did:com:133nf49v83dts7pq30csnnl7ul5unsvzz55qt20',
    label: 'commercio-demo02',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:13zexjr23zcwuav2k4gfzvrmy2dkvaa9vhdgw06',
    label: 'commercio-demo03',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1cjnpack2jqngdhj9cap23h4n3dmxcvqswgyrlc',
    label: '',
    owner: '',
    init_tx: '',
  },
]);

const ACCOUNTS_TESTNET = Object.freeze([
  {
    address: 'did:com:1n7den0m5af4akt2qhxndn6vmlxpf22xktfcqyg',
    label: 'edge',
    owner: 'commercio',
    init_tx: '',
  },
  {
    address: 'did:com:1mfddzjvr8vpeqdtm6fuay2nvnecuk9qa8usqq5',
    label: 'bono',
    owner: 'tradenet',
    init_tx: '',
  },
  {
    address: 'did:com:1yq5z4236gmcuus2t940spppwzz8uggpj5ty7ha',
    label: 'adam',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1ttxcnevaxlk58u4uuec2n7cm44rk9tsgc8tn6m',
    label: 'larry',
    owner: '',
    init_tx: '',
  },
  {
    address: 'did:com:1z884tzs6hzvza7kqlj70825p9mjpgc6r26qfvv',
    label: '',
    owner: '',
    init_tx: '',
  },
]);

const getAccounts = () => {
  switch (process.env.VUE_APP_CHAIN_LCD) {
    case 'https://lcd-mainnet.commercio.network':
      return ACCOUNTS_MAINNET;
    case 'https://lcd-devnet.commercio.network':
      return ACCOUNTS_DEVNET;
    case 'https://lcd-testnet.commercio.network':
      return ACCOUNTS_TESTNET;
    default:
      return ACCOUNTS_MAINNET;
  }
};

export default Object.freeze({
  ACCOUNTS: getAccounts(),
  LIMIT: 1000,
  SEMAPHORE_ITEMS: 2,
});
