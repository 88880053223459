const regexpBuilder = {
  getAddressRegexp(prefix) {
    return new RegExp('^' + prefix, 'igm');
  },
  getWalletRegexp(prefix) {
    return new RegExp(prefix + '[a-z0-9]{39}$', 'igm');
  },
};

export default regexpBuilder;
