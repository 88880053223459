import { cosmwasmApi } from '@/apis';
import { CHAIN, SETTINGS } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initNftList({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchNfts')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchNfts({ commit, dispatch, rootGetters }) {
    const allContracts = await dispatch('getAllContracts');
    if (!allContracts.length) {
      return;
    }
    const wallet = rootGetters['keplr/wallet'];
    for (const contract of allContracts) {
      const isMinter = await dispatch('getIsMinter', {
        address: contract.address,
        wallet,
      });
      if (!isMinter) {
        continue;
      }
      const info = await dispatch('getContractInfo', contract.address);
      const nft = { ...contract, ...info, ...{ minter: wallet } };
      commit('addNft', nft);
    }
  },
  async getAllContracts({ commit }) {
    const allContracts = [];
    const limit = SETTINGS.LIMIT.MEDIUM;
    const codeIds = [CHAIN.WASM_CODE_ID.NFT_BASE, CHAIN.WASM_CODE_ID.NFT_CHAIN];
    for (const codeId of codeIds) {
      let nextKey = null;
      do {
        try {
          const response = await cosmwasmApi.requestContracts({
            codeId,
            limit,
            nextKey,
          });
          const contracts = response.data.contracts.map((address) => ({
            codeId,
            address,
          }));
          allContracts.push(...contracts);
          nextKey = response.data.pagination.next_key;
        } catch (error) {
          commit('setError', error);
        }
      } while (nextKey);
    }
    return allContracts;
  },
  async getIsMinter({ commit }, { address, wallet }) {
    const query = '{"minter":{}}';
    const queryData = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: queryData,
      });
      return response.data.data.minter === wallet;
    } catch (error) {
      commit('setError', error);
    }
  },
  async getContractInfo({ commit }, address) {
    const query = `{"contract_info":{}}`;
    const queryData = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: queryData,
      });
      return response.data.data;
    } catch (error) {
      commit('setError', error);
    }
  },
};
