import { GasPrice, calculateFee } from '@cosmjs/stargate';
import {
  MsgExecuteContract,
  MsgInstantiateContract,
  MsgMigrateContract,
} from 'cosmjs-types/cosmwasm/wasm/v1/tx';

const msgBuilder = {
  buildMsgExecuteContract({ sender, contract, msg, funds = [] } = {}) {
    return {
      typeUrl: '/cosmwasm.wasm.v1.MsgExecuteContract',
      value: MsgExecuteContract.fromPartial({
        sender,
        contract,
        msg: new TextEncoder().encode(msg),
        funds,
      }),
    };
  },
  buildMsgInstantiateContract({
    account,
    codeId,
    label,
    msg,
    funds = [],
  } = {}) {
    return {
      typeUrl: '/cosmwasm.wasm.v1.MsgInstantiateContract',
      value: MsgInstantiateContract.fromPartial({
        sender: account,
        admin: account,
        codeId,
        label,
        msg: new TextEncoder().encode(msg),
        funds,
      }),
    };
  },
  buildMsgMigrateContract({ sender, contract, codeId, msg }) {
    return {
      typeUrl: '/cosmwasm.wasm.v1.MsgMigrateContract',
      value: MsgMigrateContract.fromPartial({
        sender,
        contract,
        codeId,
        msg: new TextEncoder().encode(msg),
      }),
    };
  },
  getFee({ chainInfo, limit, msgsCount = 1 }) {
    const feeCurrencie = chainInfo.feeCurrencies[0];
    const gasPrice = GasPrice.fromString(
      `${feeCurrencie.gasPriceStep.average * msgsCount}${
        feeCurrencie.coinMinimalDenom
      }`,
    );
    return calculateFee(limit * msgsCount, gasPrice);
  },
};

export default msgBuilder;
