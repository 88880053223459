import axios from 'axios';

import { APIS } from '@/constants';

const distributionApi = {
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestRewards(address) {
    return axios({
      url: `/delegators/${address}/rewards`,
      method: 'get',
      baseURL: `${APIS.DISTRIBUTION}`,
    });
  },
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestCommission(address) {
    return axios({
      url: `/validators/${address}/commission`,
      method: 'get',
      baseURL: `${APIS.DISTRIBUTION}`,
    });
  },
};

export default distributionApi;
