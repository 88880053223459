<template>
  <v-list-item :to="to">
    <v-list-item-icon>
      <v-icon color="primary">
        {{ mdiArrowRightThin }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-title>
      <span v-text="title" />
    </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mdiArrowRightThin } from '@mdi/js';

export default {
  name: 'BaseListRouteItem',
  props: {
    title: {
      type: String,
      required: true,
      note: 'The link name',
    },
    to: {
      type: [Object, String],
      required: true,
      note: 'The route',
    },
  },
  data() {
    return {
      mdiArrowRightThin,
    };
  },
};
</script>
