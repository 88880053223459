const errorHandler = {
  /**
   * @param {Error} error
   * @param {String} msgUnansweredRequest
   * @param {String} msgTriggeredAnError
   * @returns {Object}
   */
  process({ error, msgUnansweredRequest, msgTriggeredAnError }) {
    return error.response
      ? composeWithResponse(error)
      : composeNoResponse({ error, msgUnansweredRequest, msgTriggeredAnError });
  },
};

export default errorHandler;

/**
 *
 * @param {Error} error
 * @returns {Object}
 */
const composeWithResponse = (error) => error.response;

/**
 * @param {Error} error
 * @param {String} msgUnansweredRequest
 * @param {String} msgTriggeredAnError
 * @returns {Object}
 */
const composeNoResponse = ({
  error,
  msgUnansweredRequest,
  msgTriggeredAnError,
}) => {
  return {
    data: {
      message: error.request ? msgUnansweredRequest : msgTriggeredAnError,
    },
    status: 503,
  };
};
