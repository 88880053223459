import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const WalletsPage = loadPage('wallets/WalletsPage');

const walletsRoute = {
  path: ROUTING.PATH.WALLETS,
  name: ROUTING.NAME.WALLETS,
  component: WalletsPage,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.WALLETS,
  },
};

export default walletsRoute;
