import { cosmwasmApi } from '@/apis';
import { CHAIN } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  async initNftManagement({ commit, dispatch, rootGetters }, address) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchDetail', address)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchDetail({ commit }, address) {
    try {
      const response = await cosmwasmApi.requestContract(address);
      commit('setContract', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  updateIsInvalid({ commit }, isInvalid) {
    commit('setIsInvalid', isInvalid);
  },
  updateTokenProp({ commit }, prop) {
    commit('setTokenProp', { path: prop.path, value: prop.value });
  },
  addMetadataAttribute({ dispatch, getters }, attribute) {
    const token = getters['token'];
    const attributes =
      token && token['extension'] && token['extension']['attributes']
        ? [...token['extension']['attributes']]
        : [];
    attributes.push(attribute);
    dispatch('updateTokenProp', {
      path: 'extension.attributes',
      value: attributes,
    });
  },
  removeMetadataAttribute({ dispatch, getters }, index) {
    const token = getters['token'];
    const attributes = [...token['extension']['attributes']];
    attributes.splice(index, 1);
    dispatch('updateTokenProp', {
      path: 'extension.attributes',
      value: attributes,
    });
  },
  updateMetadataAttribute({ dispatch, getters }, { attribute, index }) {
    const token = getters['token'];
    const attributes = [...token['extension']['attributes']];
    attributes[index] = attribute;
    dispatch('updateTokenProp', {
      path: 'extension.attributes',
      value: attributes,
    });
  },
  async executeNftContract(
    { commit, dispatch, getters, rootGetters },
    { translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const contract = getters['contract'];
    const token = getters['token'];
    const msg = msgBuilder.buildMsgExecuteContract({
      sender,
      contract: contract.address,
      msg: JSON.stringify({ mint: token }),
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setLoading', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setLoading', false);
    return res;
  },
};
