import axios from 'axios';

import { APIS } from '@/constants';

const cosmwasm = {
  /**
   *
   * @param {String} codeId
   * @param {number} limit
   * @param {String} nextKey
   * @returns {AxiosPromise}
   */
  requestContracts({ codeId, limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/code/${codeId}/contracts`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
      params,
    });
  },
  /**
   *
   * @param {String} creatorAddress
   * @param {number} limit
   * @param {String} nextKey
   * @returns {AxiosPromise}
   */
  requestContractsByCreator({ creatorAddress, limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/contracts/creator/${creatorAddress}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
      params,
    });
  },
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestContract(address) {
    return axios({
      url: `/contract/${address}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
  /**
   *
   * @param {String} address
   * @param {number} limit
   * @param {String} nextKey
   * @returns {AxiosPromise}
   */
  requestContractHistory({ address, limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: `/contract/${address}/history`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
      params,
    });
  },
  /**
   *
   * @param {String} address
   * @param {String} query_data
   * @returns {AxiosPromise}
   */
  requestContractRawQuery({ address, query_data }) {
    return axios({
      url: `/contract/${address}/raw/${query_data}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
  /**
   *
   * @param {String} address
   * @param {String} query_data
   * @returns {AxiosPromise}
   */
  requestContractSmartQuery({ address, query_data }) {
    return axios({
      url: `/contract/${address}/smart/${query_data}`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestContractState(address) {
    return axios({
      url: `/contract/${address}/state`,
      method: 'get',
      baseURL: APIS.COSWMWASM,
    });
  },
};

export default cosmwasm;
