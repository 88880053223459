import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const MigrationPageList = loadPage('migration/MigrationPageList');
const MigrationPageManagement = loadPage('migration/MigrationPageManagement');
const MigrationRouter = loadPage('migration/MigrationRouter');

const migrationRoute = {
  path: ROUTING.PATH.MIGRATION,
  component: MigrationRouter,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.MIGRATION,
  },
  children: [
    {
      path: '',
      name: ROUTING.NAME.MIGRATION,
      component: MigrationPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTING.PATH.MIGRATION_LIST,
      name: ROUTING.NAME.MIGRATION_LIST,
      component: MigrationPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.MIGRATION_LIST,
      },
    },
    {
      path: ROUTING.PATH.MIGRATION_MANAGEMENT,
      name: ROUTING.NAME.MIGRATION_MANAGEMENT,
      component: MigrationPageManagement,
      props: true,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.MIGRATION_MANAGEMENT,
      },
    },
  ],
};

export default migrationRoute;
