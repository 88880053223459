const msgs = {
  addingAMarketer: 'Adding a marketing manager is mandatory in order to change marketing in the future',
  addingAMinter: 'Adding a minter is mandatory in order to mint further tokens',
  assertAndViewTx:
    'The transaction was successful and can be viewed at this link',
  copied: 'Copied',
  enterOneWalletAndBalance: 'Enter at least one wallet address and initial balance',
  noCode: 'No code',
  noMessage: 'No message',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  unansweredRequest: 'The request was made but no response was received',
  welcome: 'Welcome to the Commerce.network application where you can view wallets and manage smart contracts',
};

export default msgs;
