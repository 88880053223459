const FEE_KEY = Object.freeze({
  LP_FEE_PERCENT: 'lp_fee_percent',
  PROTOCOL_FEE_PERCENT: 'protocol_fee_percent',
});

const FILTER_VALUES = Object.freeze({
  MINTER: 'minter',
  OWNER: 'owner',
});

const FILTER_LABELS = Object.freeze({
  [FILTER_VALUES.MINTER]: 'Minter',
  [FILTER_VALUES.OWNER]: 'Owner',
});

const STATE_KEY = Object.freeze({
  BALANCE: 'balance',
  CONTRACT_INFO: 'contract_info',
  LP_TOKEN: 'lp_token',
  TOKEN_1: 'token1',
  TOKEN_2: 'token2',
  TOKEN_INFO: 'token_info',
});

export default Object({
  FEE_KEY,
  FILTER_LABELS,
  FILTER_VALUES,
  STATE_KEY,
});
