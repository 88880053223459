import { v4 as uuidv4 } from 'uuid';

const tokensManager = {
  adaptList(tokens, type) {
    return tokens.map((token) => {
      const balance = parseFloat(token.balance) / Math.pow(10, token.decimals);
      const availables = formatAmount(balance, token.decimals);
      return {
        id: uuidv4(),
        contract: token.contract,
        name: token.name,
        symbol: token.symbol,
        availables,
        amount: token.balance,
        decimals: token.decimals,
        type,
      };
    });
  },
  convertFromBase(amount, decimals) {
    return parseInt(amount) / Math.pow(10, decimals);
  },
  convertToBase(amount, decimals) {
    return Math.round(parseFloat(amount) * Math.pow(10, decimals));
  },
  format(amount, decimals) {
    if (isNaN(parseFloat(amount)) || isNaN(parseFloat(decimals))) {
      return '';
    }
    return formatAmount(amount, decimals);
  },
};

export default tokensManager;

const formatAmount = (amount, decimals = 0) =>
  new Intl.NumberFormat(undefined, {
    style: 'decimal',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  }).format(amount);
