import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const MultistepForm = loadPage('whitepaper-draft/MultistepForm');

const whitepaperDrafterRoute = {
  path: ROUTING.PATH.WHITEPAPER_DRAFTER,
  name: ROUTING.NAME.WHITEPAPER_DRAFTER,
  component: MultistepForm,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.WHITEPAPER_DRAFTER,
  },
};

export default whitepaperDrafterRoute;
