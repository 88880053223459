import { CHAIN } from '@/constants';
import { tokensManager } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  hasWallet: (state) => state.hasWallet,
  tokens: (state) =>
    tokensManager.adaptList(state.tokens, CHAIN.TOKEN_TYPE.CW20),
};
