const labels = {
  add: 'Aggiungi',
  address: 'Indirizzo',
  allowance: 'Allowance',
  amount: 'Importo',
  animationUrl: 'Animation URL',
  args: 'Args',
  availables: 'Disponibili',
  backgroundColor: 'Background color',
  balance: 'Bilancio',
  cap: 'Cap',
  ccc: 'CCC',
  code: 'Codice',
  com: 'COM',
  connectWallet: 'Connetti wallet',
  contract: 'Contratto',
  contractAddress: 'Indirizzo contratto',
  date: 'Data',
  decimals: 'Decimali',
  decrease: 'Diminuisci',
  denom: 'Denominazione',
  description: 'Descrizione',
  displayType: 'Display type',
  edit: 'Modifica',
  expectedAmount: 'Importo atteso',
  export: 'Esporta',
  externalUrl: 'External URL',
  fromToken: 'Da token',
  hash: 'Hash',
  height: 'Altezza',
  image: 'Image',
  imageData: 'Image data',
  increase: 'Aumenta',
  info: 'Info',
  initialBalances: 'Bilanci iniziali',
  instantiateContract: 'Instanzia contratto',
  instantiateDex: 'Instanzia Dex',
  lang: 'Lingua',
  liquidityPoolShortened: 'Liq. pool',
  logo: 'Logo',
  logoUrl: 'URL logo',
  lpTokenToBurn: 'LP token to burn',
  manage: 'Gestisci',
  marketing: 'Marketing',
  max: 'Max',
  message: 'Messaggio',
  migrate: 'Migra',
  minLiquidity: 'Liquidità minima',
  minOutputToken: 'Min Output token',
  mint: 'Mint',
  minter: 'Minter',
  minterAddress: 'Indirizzo minter',
  msgTypes: 'Tipi messaggio',
  name: 'Nome',
  new: 'Nuovo',
  operation: 'Operazione',
  owner: 'Proprietario',
  project: 'Progetto',
  protocol: 'Protocol',
  query: 'Query',
  rank: 'Rank',
  recipient: 'Destinatario',
  refresh: 'Ricarica',
  remove: 'Rimuovi',
  reserve: 'Riserva',
  reverse: 'Inverti',
  save: 'Salva',
  search: 'Cerca',
  select: 'Seleziona',
  share: 'Share',
  signOut: 'Esci',
  supply: 'Supply',
  swap: 'Swap',
  symbol: 'Simbolo',
  toToken: 'A token',
  token1: 'Token1',
  token1Amount: 'Importo token 1',
  token2: 'Token2',
  token2Amount: 'Importo token 2',
  token: 'Token',
  tokenId: 'Token ID',
  tokenUri: 'Token URI',
  total: 'Totale',
  totalSupply: 'Supply totale',
  traitType: 'Trait type',
  transfer: 'Trasferisci',
  type: 'Tipo',
  value: 'Value',
  wallet: 'Wallet',
  walletAddress: 'Indirizzo wallet',
  youtubeUrl: 'YouTube URL',
};

export default labels;
