import { cosmwasmApi } from '@/apis';
import { CHAIN } from '@/constants';
import { msgBuilder, stringHandler } from '@/utils';

export default {
  async initMyNftMangement(
    { commit, dispatch, rootGetters },
    { contract, tokenId },
  ) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchNft', { contract, tokenId })];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchNft({ commit }, { contract, tokenId }) {
    const query = `{"nft_info":{"token_id":"${tokenId}"}}`;
    const queryData = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address: contract,
        query_data: queryData,
      });
      const data = response.data.data;
      const nft = {
        id: tokenId,
        contract,
      };
      if (data.extension) {
        nft.extension = { ...data.extension };
      }
      if (data.token_uri) {
        nft.token_uri = data.token_uri;
      }
      commit('setNft', nft);
    } catch (error) {
      commit('setError', error);
    }
  },
  async executeContract(
    { commit, dispatch, rootGetters },
    { contract, textMsg, translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const msg = msgBuilder.buildMsgExecuteContract({
      sender,
      contract,
      msg: textMsg,
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setHandling', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setHandling', false);
    return res;
  },
};
