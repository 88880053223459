<template>
  <v-card color="light-blue lighten-5" flat>
    <v-card-title>
      <div v-text="title" />
    </v-card-title>
    <v-card-text>
      <div
        v-if="$vuetify.breakpoint.smAndDown"
        class="response__text-small"
        v-text="jsonData"
      />
      <pre
        v-else-if="$vuetify.breakpoint.mdOnly"
        class="response__text-small"
        v-text="response"
      />
      <pre v-else v-text="response" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'TheJsonViewer',
  props: {
    jsonData: {
      type: Object,
      required: true,
      note: 'The data to show',
    },
    title: {
      type: String,
      default: '',
      note: 'The title of the card',
    },
  },
  computed: {
    response() {
      return this.jsonData ? JSON.stringify(this.jsonData, undefined, 2) : '';
    },
  },
};
</script>

<style scoped>
.response__text-small {
  font-family: monospace;
  font-size: 0.7rem;
}
</style>
