import { orderBy } from 'lodash';

import { CHAIN, WALLETS } from '@/constants';

const walletsManager = {
  embedWallets(wallets) {
    const orderedWallets = orderWallets(wallets);
    let rank = 1;
    return orderedWallets.map((wallet) => {
      const labelAndOwner = findLabelAndOwner(wallet, WALLETS.ACCOUNTS);
      return {
        rank: rank++,
        label: labelAndOwner.label,
        owner: labelAndOwner.owner,
        address: wallet.address,
        com: wallet[CHAIN.TOKEN.DENOM] / Math.pow(10, 6),
        ccc: wallet[CHAIN.STABLE_COIN.DENOM] / Math.pow(10, 6),
        share:
          wallet[CHAIN.TOKEN.DENOM] / Math.pow(10, 6) / CHAIN.TOKENS_AMOUNT,
      };
    });
  },
  mergeWallets(wallets) {
    const result = wallets.reduce((acc, { address, value }) => {
      acc[address] ??= { address: address, value: [] };
      if (Array.isArray(value)) {
        acc[address].value = acc[address].value.concat(value);
      } else {
        acc[address].value.push(value);
      }
      return acc;
    }, {});
    return result;
  },
  sumWalletValues(wallets) {
    const items = [];
    const total = { [CHAIN.TOKEN.DENOM]: 0, [CHAIN.STABLE_COIN.DENOM]: 0 };
    for (const k in wallets) {
      const wallet = wallets[k];
      const item = {
        address: wallet.address,
        [CHAIN.TOKEN.DENOM]: 0,
        [CHAIN.STABLE_COIN.DENOM]: 0,
      };
      wallet.value.forEach((val) => {
        const amount = parseInt(val.amount);
        if (val.denom === CHAIN.TOKEN.DENOM) {
          item[CHAIN.TOKEN.DENOM] += amount;
          total[CHAIN.TOKEN.DENOM] += amount;
        }
        if (val.denom === CHAIN.STABLE_COIN.DENOM) {
          item[CHAIN.STABLE_COIN.DENOM] += amount;
          total[CHAIN.STABLE_COIN.DENOM] += amount;
        }
      });
      items.push(item);
    }
    return { items, total };
  },
};

export default walletsManager;

const findLabelAndOwner = (wallet, accounts) => {
  const index = accounts.findIndex(
    (account) => account.address === wallet.address,
  );
  if (index < 0) {
    return { label: '', owner: '' };
  }
  const account = accounts[index];
  return { label: account.label, owner: account.owner };
};

const orderWallets = (wallets) =>
  orderBy(wallets, [[CHAIN.TOKEN.DENOM]], ['desc']);
