import { wasmsApi } from '@/apis';
import { CHAIN } from '@/constants';

export default {
  async initCw20List({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchWasmBalances')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchWasmBalances({ commit, rootGetters }) {
    const wallet = rootGetters['keplr/wallet'];
    try {
      const response = await wasmsApi.requestAccountBalances({
        accountAddress: wallet,
        cw20Code: CHAIN.WASM_CODE_ID.CW20,
        dexCode: CHAIN.WASM_CODE_ID.DEX,
      });
      commit('setTokens', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
};
