<template>
  <v-footer app>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      color="primary"
      fab
      dark
      small
      absolute
      top
      right
      @click="toTop"
    >
      <v-icon dark>{{ mdiArrowUpBold }}</v-icon>
    </v-btn>
    <v-row class="my-0 py-0 py-sm-2 overline rm__text-transform">
      <v-col cols="12" sm="2" class="my-0 py-0">
        <span class="d-flex justify-center justify-sm-start" v-text="version" />
      </v-col>
      <v-col cols="12" sm="8" class="my-0 py-0">
        <span class="font-italic d-flex justify-center" v-html="copy" />
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mdiArrowUpBold } from '@mdi/js';
import { SETTINGS } from '@/constants';

export default {
  name: 'TheFooter',
  data() {
    return {
      mdiArrowUpBold,
      fab: false,
    };
  },
  computed: {
    copy() {
      const year = new Date().getFullYear();
      return `&copy;&nbsp;${year} ${SETTINGS.COPYRIGHT}`;
    },
    version() {
      return `v${process.env.APP_VERSION}`;
    },
  },
  methods: {
    onScroll() {
      if (typeof window === 'undefined') return;
      this.fab = window.pageYOffset > 25;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.rm__text-transform {
  text-transform: none !important;
}
</style>
