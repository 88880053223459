const KEY = Object.freeze({
  ADD_LIQUIDITY: 'add_liquidity',
  DECREASE_ALLOWANCE: 'decrease_allowance',
  INCREASE_ALLOWANCE: 'increase_allowance',
  MINT: 'mint',
  REMOVE_LIQUIDITY: 'remove_liquidity',
  SWAP: 'swap',
  TRANSFER_NFT: 'transfer_nft',
  UPDATE_MARKETING: 'update_marketing',
});

export default Object.freeze({
  KEY,
});
