import { cosmwasmApi } from '@/apis';
import { CHAIN } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  async initMigrationManagement({ commit, dispatch }, address) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContract', address)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchContract({ commit }, address) {
    try {
      const response = await cosmwasmApi.requestContract(address);
      commit('setContract', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  async executeContract(
    { commit, dispatch, rootGetters },
    { contract, codeId, textMsg, translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const msg = msgBuilder.buildMsgMigrateContract({
      sender,
      contract,
      codeId,
      msg: textMsg,
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setHandling', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setHandling', false);
    return res;
  },
};
