import { CHAIN } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  initNftNew({ commit, rootGetters }) {
    commit('reset');
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      return;
    }
  },
  updateContractProp({ commit }, prop) {
    commit('setContractProp', { path: prop.path, value: prop.value });
  },
  updateIsInvalid({ commit }, isInvalid) {
    commit('setIsInvalid', isInvalid);
  },
  async instantiateContract(
    { commit, dispatch, getters, rootGetters },
    { translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const account = rootGetters['keplr/wallet'];
    const codeId = getters['contractCodeId'];
    const contract = getters['contractToSubmit'];
    const label = `Init ${contract.name} contract`;
    const funds = [];
    const msg = msgBuilder.buildMsgInstantiateContract({
      account,
      codeId,
      funds,
      label,
      msg: JSON.stringify(contract),
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setHandling', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setHandling', false);
    return res;
  },
};
