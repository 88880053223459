import { contractAdapter } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  hasWallet: (state) => state.hasWallet,
  contract: (state) => state.contract,
  contractToSubmit: (state) =>
    state.contract ? contractAdapter.adaptCW20(state.contract) : null,
  initialBalances: (state) =>
    state.contract && state.contract['initial_balances']
      ? state.contract['initial_balances']
      : [],
  isValidContract: (state) => !state.isInvalid,
};
