export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  hasWallet: (state) => state.hasWallet,
  contract: (state) => state.contract,
  contractCodeId: (state) =>
    state.contract ? state.contract.contract_info.code_id : '',
  token: (state) => state.token,
  tokenMetadataAttributes: (state) =>
    state.token && state.token.extension && state.token.extension.attributes
      ? state.token.extension.attributes
      : [],
  isValidToken: (state) => !state.isInvalid,
};
