import { bankApi, wasmsApi } from '@/apis';
import { CHAIN, SETTINGS } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  async initDexNew({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchCW20s'), dispatch('fetchSupply')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchCW20s({ commit, rootGetters }) {
    const wallet = rootGetters['keplr/wallet'];
    try {
      const response = await wasmsApi.requestAccountBalances({
        accountAddress: wallet,
        cw20Code: CHAIN.WASM_CODE_ID.CW20,
        dexCode: CHAIN.WASM_CODE_ID.DEX,
      });
      commit('setCW20s', response.data.balances);
    } catch (error) {
      commit('setError', error);
    }
  },
  async fetchSupply({ commit }) {
    let nextKey = null;
    do {
      const response = await bankApi.requestSupply({
        limit: SETTINGS.LIMIT.LOW,
        nextKey,
      });
      commit('setNatives', response.data.supply);
      nextKey = response.data.pagination.next_key;
    } while (nextKey);
  },
  updateContractProp({ commit }, prop) {
    commit('setContractProp', { path: prop.path, value: prop.value });
  },
  async instantiateContract(
    { commit, dispatch, getters, rootGetters },
    { translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const account = rootGetters['keplr/wallet'];
    const codeId = CHAIN.WASM_CODE_ID.DEX;
    const contract = getters['contract'];
    contract['lp_token_code_id'] = parseInt(CHAIN.WASM_CODE_ID.CW20);
    const label = 'CommercioDEX';
    const funds = [];
    const msg = msgBuilder.buildMsgInstantiateContract({
      account,
      codeId,
      funds,
      label,
      msg: JSON.stringify(contract),
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setLoading', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setLoading', false);
    return res;
  },
};
