const titles = {
  addLiquidity: 'Add liquidity',
  attributes: 'Attributes',
  contract: 'Contract',
  contractDetails: 'Contract details',
  cw20BaseContract: 'CW20 Base Contract',
  cw20List: 'CW20 List',
  cw20Migration: 'CW20 Migration',
  dashboard: 'Dashboard',
  dexList: 'Dex List',
  events: 'Events',
  fee: 'Fee',
  history: 'History',
  liquidity: 'Liquidity',
  lpToken: 'Lp token',
  manageAllowance: 'Manage allowance',
  marketing: 'Marketing',
  marketingDetails: 'Marketing details',
  metadata: 'Metadata',
  migrateContract: 'Migrate contract',
  migration: 'Migration',
  mintNft: 'Mint NFT',
  minting: 'Minting',
  mintingDetails: 'Minting details',
  moreInfo: 'More info',
  myNft: 'My Nft',
  newDex: 'New Dex (pool)',
  newNft: 'New Nft',
  nftList: 'Nft List',
  owner: 'Owner',
  percent: 'Percent',
  preview: 'Preview',
  recipient: 'Recipient',
  removeLiquidity: 'Remove liquidity',
  swap: 'Swap',
  token1: 'Token 1',
  token2: 'Token 2',
  tokenDetails: 'Token details',
  tokenInfo: 'Token info',
  tos: 'TOS',
  transferNft: 'Transfer Nft',
  wallets: 'Wallets',
  whitepaperDrafter: 'Whitepaper Drafter'
};

export default titles;
