import { set } from 'lodash';
import { initState } from './index';

export default {
  reset(state) {
    Object.assign(state, initState());
  },
  setError(state, error) {
    state.error = error;
  },
  setLoading(state, payload) {
    state.isLoading = payload;
  },
  setHasWallet(state, payload) {
    state.hasWallet = payload;
  },
  setContract(state, payload) {
    state.contract = payload;
  },
  setIsInvalid(state, payload) {
    state.isInvalid = payload;
  },
  setTokenProp(state, payload) {
    const obj = { ...state.token };
    set(obj, payload.path, payload.value);
    state.token = { ...obj };
  },
};
