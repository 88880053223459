import { cosmwasmApi, txApi } from '@/apis';
import { CHAIN, SETTINGS } from '@/constants';
import { msgBuilder, stringHandler } from '@/utils';

export default {
  async initCw20Management({ commit, dispatch, rootGetters }, address) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    await dispatch('fetchDetails', address);
    commit('setLoading', false);
  },
  async fetchDetails({ commit, dispatch }, address) {
    const queryMarketing = '{"marketing_info":{}}';
    const queryMinter = '{"minter":{}}';
    const queryTokenInfo = '{"token_info":{}}';
    const requests = [
      dispatch('getDetails', {
        address,
        queryData: queryMarketing,
        path: 'marketing',
      }),
      dispatch('getDetails', {
        address,
        queryData: queryMinter,
        path: 'minting',
      }),
      dispatch('getDetails', {
        address,
        queryData: queryTokenInfo,
        path: 'info',
      }),
      dispatch('getEvents', address),
      dispatch('getHistory', address),
    ];
    await Promise.all(requests);
    commit('setContractProp', {
      path: 'address',
      value: address,
    });
  },
  async getDetails({ commit }, { address, queryData, path }) {
    const query = stringHandler.encodeToBase64(queryData);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: query,
      });
      commit('setContractProp', {
        path,
        value: response.data.data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  async getEvents({ commit }, address) {
    const txs = [];
    const events = [`wasm._contract_address='${address}'`];
    const limit = SETTINGS.LIMIT.MEDIUM;
    let nextKey = null;
    try {
      do {
        const response = await txApi.requestTxs({ events, limit, nextKey });
        txs.push(...response.data.tx_responses);
        nextKey = response.data.pagination.next_key;
      } while (nextKey);
      commit('setContractProp', {
        path: 'events',
        value: txs,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  async getHistory({ commit }, address) {
    try {
      const limit = SETTINGS.LIMIT.MEDIUM;
      const entries = [];
      let nextKey = null;
      do {
        const response = await cosmwasmApi.requestContractHistory({
          address,
          limit,
          nextKey,
        });
        entries.push(...response.data.entries);
        nextKey = response.data.pagination.next_key;
      } while (nextKey);
      commit('setContractProp', {
        path: 'history',
        value: entries,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  async executeContract(
    { commit, dispatch, rootGetters },
    { contract, textMsg, translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const sender = rootGetters['keplr/wallet'];
    const msg = msgBuilder.buildMsgExecuteContract({
      sender,
      contract,
      msg: textMsg,
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setHandling', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    await dispatch('fetchDetails', contract);
    commit('setHandling', false);
    return res;
  },
};
