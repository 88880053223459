<template>
  <v-list-item>
    <v-list-item-title class="d-flex flex-row align-center">
      <router-link class="text-decoration-none" :to="to">
        <span class="font-weight-bold" v-text="title" />
      </router-link>
      <slot />
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'BaseDrawerMenuItem',
  props: {
    title: {
      type: String,
      required: true,
      note: "The item's label",
    },
    to: {
      type: Object,
      required: true,
      note: "The item's link",
    },
  },
};
</script>
