import alerts from './alerts';
import labels from './labels';
import msgs from './msgs';
import titles from './titles';

export default {
  alerts,
  labels,
  msgs,
  titles,
};
