const titles = {
  addLiquidity: 'Aggiungi liquidità',
  attributes: 'Attributi',
  contract: 'Contratto',
  contractDetails: 'Dettagli contratto',
  cw20BaseContract: 'CW20 Contratto base',
  cw20List: 'Elenco CW20',
  cw20Migration: 'CW20 Migrazione',
  dashboard: 'Cruscotto',
  dexList: 'Elenco Dex',
  events: 'Eventi',
  fee: 'Fee',
  history: 'History',
  liquidity: 'Liquidità',
  lpToken: 'Lp token',
  manageAllowance: 'Gestisci allowance',
  marketing: 'Marketing',
  marketingDetails: 'Dettagli marketing',
  metadata: 'Metadata',
  migrateContract: 'Migra contratto',
  migration: 'Migrazione',
  mintNft: 'Mint NFT',
  minting: 'Minting',
  mintingDetails: 'Dettagli minting',
  moreInfo: 'Più info',
  myNft: 'My Nft',
  newDex: 'Nuovo Dex (pool)',
  newNft: 'Nuovo Nft',
  nftList: 'Elenco Nft',
  owner: 'Proprietario',
  percent: 'Percentuale',
  preview: 'Anteprima',
  recipient: 'Destinatario',
  removeLiquidity: 'Rimuovi liquidità',
  swap: 'Swap',
  token1: 'Token 1',
  token2: 'Token 2',
  tokenDetails: 'Dettagli token',
  tokenInfo: 'Token info',
  transferNft: 'Trasferisci Nft',
  tos: 'TOS',
  wallets: 'Wallets',
  whitepaperDrafter: 'Whitepaper Drafter',
};

export default titles;
