<template>
  <nav>
    <v-navigation-drawer v-model="drawer" app fixed disable-resize-watcher>
      <the-drawer />
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark dense flat>
      <v-row class="d-flex align-center">
        <v-col cols="2" md="5" class="d-flex justify-start">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer">
            <slot>
              <v-icon size="35">{{ drawer ? mdiWindowClose : mdiMenu }}</v-icon>
            </slot>
          </v-app-bar-nav-icon>
        </v-col>
        <v-col cols="3" md="2" class="d-flex justify-center">
          <router-link :to="ROUTING.PATH.DASHBOARD">
            <v-img :src="SETTINGS.LOGO" alt="Commercio Logo" width="40" />
          </router-link>
        </v-col>
        <v-col cols="7" md="5" class="d-flex justify-end">
          <the-connect-wallet-button />
        </v-col>
      </v-row>
    </v-app-bar>
    <the-connect-snack-bar />
  </nav>
</template>

<script>
import TheConnectSnackBar from './app-bar/TheConnectSnackBar.vue';
import TheConnectWalletButton from './app-bar/TheConnectWalletButton.vue';
import TheDrawer from './TheDrawer.vue';

import { ROUTING, SETTINGS } from '@/constants';
import { mdiMenu, mdiWindowClose } from '@mdi/js';

export default {
  name: 'TheAppBar',
  components: {
    TheConnectSnackBar,
    TheConnectWalletButton,
    TheDrawer,
  },
  data() {
    return {
      ROUTING,
      SETTINGS,
      mdiMenu,
      mdiWindowClose,
      drawer: false,
    };
  },
};
</script>
