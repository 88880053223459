const PATH = Object.freeze({
  CW_20: '/cw20',
  CW_20_LIST: 'list',
  CW_20_MANAGEMENT: 'management/:id',
  CW_20_NEW: 'new',
  DASHBOARD: '/dashboard',
  DEX: '/dex',
  DEX_LIST: 'list',
  DEX_MANAGEMENT: 'management/:id',
  DEX_MANAGEMENT_DASHBOARD: 'dashboard',
  DEX_MANAGEMENT_ADD: 'add',
  DEX_MANAGEMENT_REMOVE: 'remove',
  DEX_MANAGEMENT_SWAP: 'swap',
  DEX_NEW: 'new',
  MIGRATION: '/migration',
  MIGRATION_LIST: 'list',
  MIGRATION_MANAGEMENT: 'management/:id',
  MY_NFT: '/my-nft',
  MY_NFT_LIST: 'list',
  MY_NFT_MANAGEMENT: 'management/:contract/:id',
  NFT: '/nft',
  NFT_LIST: 'list',
  NFT_MANAGEMENT: 'management/:id',
  NFT_NEW: 'new',
  LOGIN: '/login',
  NOT_FOUND: '/:notFound(.*)*',
  ROOT: '/',
  TOS: '/tos',
  WALLETS: '/wallets',
  WHITEPAPER_DRAFTER: '/whitepaper-drafter',
});

const NAME = Object.freeze({
  CW_20: 'cw20',
  CW_20_LIST: 'cw20List',
  CW_20_MANAGEMENT: 'cw20Mangement',
  CW_20_NEW: 'cw20New',
  DASHBOARD: 'dashboard',
  DEX: 'dex',
  DEX_LIST: 'dexList',
  DEX_MANAGEMENT: 'dexManagement',
  DEX_MANAGEMENT_DASHBOARD: 'dexManagementDashboard',
  DEX_MANAGEMENT_ADD: 'dexManagementAdd',
  DEX_MANAGEMENT_REMOVE: 'dexManagementRemove',
  DEX_MANAGEMENT_SWAP: 'dexManagementSwap',
  DEX_NEW: 'dexNew',
  MIGRATION: 'migration',
  MIGRATION_LIST: 'migrationList',
  MIGRATION_MANAGEMENT: 'migrationManagement',
  MY_NFT: 'myNft',
  MY_NFT_LIST: 'myNftList',
  MY_NFT_MANAGEMENT: 'myNftManagement',
  NFT: 'nft',
  NFT_LIST: 'nftList',
  NFT_MANAGEMENT: 'nftManagement',
  NFT_NEW: 'nftNew',
  NOT_FOUND: 'notFound',
  TOS: 'tos',
  WALLETS: 'wallets',
  WHITEPAPER_DRAFTER: 'whitepaperDrafter',
});

const TITLE = Object.freeze({
  CW_20: 'CW20',
  CW_20_DASHBOARD: 'CW20 Dashboard',
  CW_20_LIST: 'CW20 List',
  CW_20_MANAGEMENT: 'CW20 Mangement',
  CW_20_NEW: 'CW20 New',
  DASHBOARD: 'Dashboard',
  DEX: 'Dex',
  DEX_LIST: 'Dex List',
  DEX_MANAGEMENT: 'Dex Management',
  DEX_MANAGEMENT_DASHBOARD: 'Dex Management Dashboard',
  DEX_MANAGEMENT_ADD: 'Dex Management Add',
  DEX_MANAGEMENT_REMOVE: 'Dex Management Remove',
  DEX_MANAGEMENT_SWAP: 'Dex Management Swap',
  DEX_NEW: 'Dex New',
  MIGRATION: 'Migration',
  MIGRATION_LIST: 'Migration List',
  MIGRATION_MANAGEMENT: 'Migration Management',
  MY_NFT: 'My Nft',
  MY_NFT_LIST: 'My Nft List',
  MY_NFT_MANAGEMENT: 'My Nft Management',
  NFT: 'Nft',
  NFT_LIST: 'Nft List',
  NFT_MANAGEMENT: 'Nft Management',
  NFT_NEW: 'Nft New',
  NOT_FOUND: 'Not Found',
  TOS: 'TOS',
  WALLETS: 'Wallets',
  WHITEPAPER_DRAFTER: 'Whitepaper Drafter',
});

const USER_ROLE = Object.freeze({
  ANY: 'any',
  AUTHORIZED: 'authorized',
  NOT_AUTHORIZED: 'notAuthorized',
});

export default Object.freeze({
  NAME,
  PATH,
  TITLE,
  USER_ROLE,
});
