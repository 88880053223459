import axios from 'axios';

import { APIS } from '@/constants';

const authApi = {
  /**
   *
   * @param {Number} limit
   * @returns {AxiosPromise}
   */
  requestAccounts(limit) {
    return axios({
      url: '/accounts',
      method: 'get',
      baseURL: `${APIS.AUTH}`,
      params: {
        'pagination.limit': limit,
      },
    });
  },
};

export default authApi;
