import Keycloak from 'keycloak-js';

import { KEYCLOAK_CONFIG } from '@/constants';

const initOptions = {
  url: KEYCLOAK_CONFIG.URL,
  realm: KEYCLOAK_CONFIG.REALM,
  clientId: KEYCLOAK_CONFIG.CLIENT_ID,
};

const keycloak = new Keycloak(initOptions);
let refreshIntervalId;

export default {
  async initApp({ commit, dispatch }) {
    commit('setLoading', true);
    await dispatch('setupKeycloak');
    if (keycloak.authenticated) {
      const token = keycloak.token;
      commit('setToken', token);
      await dispatch('fetchProfile');
    }
    commit('setLoading', false);
  },
  async setupKeycloak({ commit, dispatch }) {
    try {
      await keycloak.init({
        onLoad: KEYCLOAK_CONFIG.ON_LOAD,
        checkLoginIframe: false,
      });
      refreshIntervalId = setInterval(async () => {
        await dispatch('refreshToken');
      }, KEYCLOAK_CONFIG.REFRESH_TOKEN_TIME * 1000);
    } catch (error) {
      commit('setToken', '');
    }
  },
  async refreshToken({ commit }) {
    try {
      const response = await keycloak.updateToken(70);
      if (response) {
        commit('setToken', keycloak.token);
      }
    } catch (error) {
      commit('setToken', '');
    }
  },
  async fetchProfile({ commit }) {
    try {
      const profile = await keycloak.loadUserProfile();
      commit('setProfile', profile);
    } catch (error) {
      commit('setError', error);
    }
  },
  async logOut({ commit }) {
    clearInterval(refreshIntervalId);
    try {
      await keycloak.logout();
      commit('reset');
    } catch (error) {
      commit('setError', error);
    }
  },
};
