import { KEYCLOAK_CONFIG } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  profile: (state) => state.profile,
  token: (state) => state.token,
  isAuthenticated: (state) => state.token !== '',
  userAttribute: (state) =>
    state.profile &&
    state.profile.attributes &&
    state.profile.attributes[KEYCLOAK_CONFIG.USER_ATTRIBUTE.NAME] &&
    state.profile.attributes[KEYCLOAK_CONFIG.USER_ATTRIBUTE.NAME].length > 0
      ? state.profile.attributes[KEYCLOAK_CONFIG.USER_ATTRIBUTE.NAME][0]
      : '',
};
