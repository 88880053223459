import axios from 'axios';

import { APIS } from '@/constants';

const wasms = {
  /**
   *
   * @param {String} accountAddress
   * @param {String} cw20Code
   * @param {String} dexCode
   * @returns {AxiosPromise}
   */
  requestAccountBalances({ accountAddress, cw20Code, dexCode }) {
    return axios({
      url: `/balances/${accountAddress}/${cw20Code}/${dexCode}`,
      method: 'get',
      baseURL: APIS.WASMS,
    });
  },
  /**
   *
   * @param {String} codeId
   * @returns {AxiosPromise}
   */
  requestContractInfos(codeId) {
    return axios({
      url: `/contracts/${codeId}`,
      method: 'get',
      baseURL: APIS.WASMS,
    });
  },
};

export default wasms;
