import { wasmsApi } from '@/apis';

export default {
  async initMigrationList({ commit, dispatch }, codeId) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContractInfos', codeId)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchContractInfos({ commit }, codeId) {
    try {
      const response = await wasmsApi.requestContractInfos(codeId);
      commit('setContracts', response.data.contracts);
    } catch (error) {
      commit('setError', error);
    }
  },
};
