import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';

dayjs.extend(dayOfYear);

const dateHandler = {
  /**
   *
   * @param {Date} date
   * @returns {String}
   */
  getFormatByElapsedTime(date) {
    const now = dayjs();
    const d = dayjs(date);
    return now.dayOfYear() !== d.dayOfYear() || now.year() !== d.year()
      ? d.format('DD/MM/YYYY')
      : d.format('HH:mm [GMT]Z');
  },
};

export default dateHandler;
