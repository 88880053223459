import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const DashboardPage = loadPage('dashboard/DashboardPage');

const dashboardRoute = {
  path: ROUTING.PATH.DASHBOARD,
  name: ROUTING.NAME.DASHBOARD,
  component: DashboardPage,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.DASHBOARD,
  },
};

export default dashboardRoute;
