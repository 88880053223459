import axios from 'axios';

import { APIS } from '@/constants';

const bankApi = {
  /**
   *
   * @param {String} address
   * @returns {AxiosPromise}
   */
  requestBalances(address) {
    return axios({
      url: `/balances/${address}`,
      method: 'get',
      baseURL: `${APIS.BANK}`,
    });
  },
  /**
   *
   * @param {number} limit
   * @param {String} nextKey
   * @returns {AxiosPromise}
   */
  requestSupply({ limit, nextKey }) {
    const params = { 'pagination.limit': limit };
    if (nextKey) {
      params['pagination.key'] = nextKey;
    }
    return axios({
      url: '/supply',
      method: 'get',
      baseURL: `${APIS.BANK}`,
      params,
    });
  },
};

export default bankApi;
