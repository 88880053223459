import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const MyNftPageList = loadPage('my-nft/MyNftPageList');
const MyNftPageManagement = loadPage('my-nft/MyNftPageManagement');
const MyNftRouter = loadPage('my-nft/MyNftRouter');

const myNftRoute = {
  path: ROUTING.PATH.MY_NFT,
  component: MyNftRouter,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.MY_NFT,
  },
  children: [
    {
      path: '',
      name: ROUTING.NAME.MY_NFT,
      component: MyNftPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTING.PATH.MY_NFT_LIST,
      name: ROUTING.NAME.MY_NFT_LIST,
      component: MyNftPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.MY_NFT_LIST,
      },
    },
    {
      path: ROUTING.PATH.MY_NFT_MANAGEMENT,
      name: ROUTING.NAME.MY_NFT_MANAGEMENT,
      component: MyNftPageManagement,
      props: true,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.MY_NFT_MANAGEMENT,
      },
    },
  ],
};

export default myNftRoute;
