const LOCALES = Object.freeze([
  {
    default: true,
    value: 'en',
    text: 'English',
    label: 'EN',
    flag: 'gb',
  },
  {
    default: false,
    value: 'it',
    text: 'Italiano',
    label: 'IT',
    flag: 'it',
  },
]);

export default Object.freeze({
  BROWSER_STORAGE_KEY: 'chain-stats-backoffice-locale',
  LOCALES,
});
