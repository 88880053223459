import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const CW20PageList = loadPage('cw20/CW20PageList');
const CW20PageManagement = loadPage('cw20/CW20PageManagement');
const CW20PageNew = loadPage('cw20/CW20PageNew');
const CW20Router = loadPage('cw20/CW20Router');

const cw20Route = {
  path: ROUTING.PATH.CW_20,
  component: CW20Router,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.CW_20,
  },
  children: [
    {
      path: '',
      name: ROUTING.NAME.CW_20,
      component: CW20PageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTING.PATH.CW_20_LIST,
      name: ROUTING.NAME.CW_20_LIST,
      component: CW20PageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.CW_20_LIST,
      },
    },
    {
      path: ROUTING.PATH.CW_20_MANAGEMENT,
      name: ROUTING.NAME.CW_20_MANAGEMENT,
      component: CW20PageManagement,
      props: true,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.CW_20_MANAGEMENT,
      },
    },
    {
      path: ROUTING.PATH.CW_20_NEW,
      name: ROUTING.NAME.CW_20_NEW,
      component: CW20PageNew,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.CW_20_NEW,
      },
    },
  ],
};

export default cw20Route;
