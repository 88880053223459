import tokensManager from './tokens-manager';

const contractAdapter = {
  adaptCW20(data) {
    const contract = JSON.parse(JSON.stringify(data));
    const initialBalances = contract['initial_balances'].map((balance) => {
      balance.amount = tokensManager
        .convertToBase(balance.amount, contract['decimals'])
        .toString();
      return balance;
    });
    contract['initial_balances'] = initialBalances;
    if (contract['mint'] && contract['mint']['cap']) {
      const cap = tokensManager
        .convertToBase(contract['mint']['cap'], contract['decimals'])
        .toString();
      contract['mint']['cap'] = cap;
    }
    return contract;
  },
  adaptNft(data) {
    const contract = JSON.parse(JSON.stringify(data));
    return {
      minter: contract.minter,
      name: contract.name,
      symbol: contract.symbol,
    };
  },
};

export default contractAdapter;
