import { contractAdapter } from '@/utils';

export default {
  error: (state) => state.error,
  isHandling: (state) => state.isHandling,
  hasWallet: (state) => state.hasWallet,
  contract: (state) => state.contract,
  contractCodeId: (state) => (state.contract ? state.contract.codeId : ''),
  contractToSubmit: (state) =>
    state.contract ? contractAdapter.adaptNft(state.contract) : null,
  isValidContract: (state) => !state.isInvalid,
};
