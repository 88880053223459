import Vue from 'vue';
import Vuex from 'vuex';

import application from './application';
import cw20List from './cw20/list';
import cw20Management from './cw20/management';
import cw20New from './cw20/new';
import dexList from './dex/list';
import dexNew from './dex/new';
import dexManagement from './dex/management';
import keplr from './keplr';
import migrationList from './migration/list';
import migrationManagement from './migration/management';
import myNftList from './my-nft/list';
import myNftManagement from './my-nft/management';
import nftList from './nft/list';
import nftManagement from './nft/management';
import nftNew from './nft/new';
import wallets from './wallets';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    // root
    application,
    // namespaced
    cw20List,
    cw20Management,
    cw20New,
    dexList,
    dexNew,
    dexManagement,
    keplr,
    migrationList,
    migrationManagement,
    myNftList,
    myNftManagement,
    nftList,
    nftManagement,
    nftNew,
    wallets,
  },
});
