const alerts = {
  amonutHigherThanMaximumPossible: 'L\'importo è superiore al massimo possibile',
  amonutLessThanBalance: 'L\'importo deve essere inferiore al saldo',
  amonutLessThanMinimumRequired: 'L\'importo è inferiore al minimo richiesto',
  amountGreaterZero: 'L\'importo deve essere maggiore od uguale a zero',
  connected: 'Connesso',
  dataNotAvailable: 'In questo momento i dati, purtroppo, non sono disponibili.',
  failedToDetectTheChain: 'Impossibile identificare la chain',
  failedToSuggestTheChain: 'Impossibile suggerire la chain',
  higherAmount: 'L\'importo è superiore a quello disponibile',
  installKeplrExtension: 'Si prega di installare l\'estensione Keplr',
  invalid: 'Non valido',
  invalidJson: 'JSON non valido',
  mandatoryField: 'Questo campo è obbligatorio',
  noAccountFound: 'Nessun conto trovato. Utilizza l\'estensione Keplr per crearlo o importarlo.',
  noCode: 'Codice non disponibile',
  noDataAvailable: 'Nessun dato disponibile',
  noMessage: 'Messaggio non disponibile',
  noWalletConnected: 'Nessun wallet connesso, per poter utilizzare questa funzione devi attivarne uno usando il bottone nella barra',
  onlyCharsAndDash: 'Solo caratteri e \'-\' sono ammessi',
  pageNotFound: '404 - Pagina non trovata',
  positiveAmount: 'L\'importo deve essere positivo',
  searchEvents: 'Gli eventi possono essere filtrati per data, tipo e hash; la data deve essere nel formato YYYY-MM-DD',
  symbolLength: 'Il simbolo deve essere compreso tra 3 e 12 caratteri',
  tooLongField: 'Il valore inserito è troppo lungo',
};

export default alerts;
