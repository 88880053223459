import { CHAIN } from '@/constants';
import { msgBuilder } from '@/utils';

export default {
  initCw20New({ commit, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    commit('setLoading', false);
  },
  updateContractProp({ commit }, prop) {
    commit('setContractProp', { path: prop.path, value: prop.value });
  },
  addInitialBalance({ dispatch, getters }, balance) {
    const contract = getters['contract'];
    const balances =
      contract && contract['initial_balances']
        ? [...contract['initial_balances']]
        : [];
    balances.push(balance);
    dispatch('updateContractProp', {
      path: 'initial_balances',
      value: balances,
    });
  },
  removeInitialBalance({ dispatch, getters }, index) {
    const contract = getters['contract'];
    const balances = contract['initial_balances'];
    balances.splice(index, 1);
    dispatch('updateContractProp', {
      path: 'initial_balances',
      value: balances,
    });
  },
  updateInitialBalance({ dispatch, getters }, { balance, index }) {
    const contract = getters['contract'];
    const balances = contract['initial_balances'];
    balances[index] = balance;
    dispatch('updateContractProp', {
      path: 'initial_balances',
      value: balances,
    });
  },
  updateIsInvalid({ commit }, isInvalid) {
    commit('setIsInvalid', isInvalid);
  },
  async instantiateContract(
    { commit, dispatch, getters, rootGetters },
    { translator, context },
  ) {
    const chainInfo = CHAIN.INFO;
    if (!rootGetters['keplr/isConnected']) {
      await dispatch(
        'keplr/suggestChain',
        { chainInfo, translator, context },
        { root: true },
      );
    }
    const account = rootGetters['keplr/wallet'];
    const codeId = CHAIN.WASM_CODE_ID.CW20;
    const contract = getters['contractToSubmit'];
    const label = `Init ${contract.name} contract`;
    const funds = [];
    const msg = msgBuilder.buildMsgInstantiateContract({
      account,
      codeId,
      funds,
      label,
      msg: JSON.stringify(contract),
    });
    const fee = msgBuilder.getFee({ chainInfo, limit: CHAIN.GAS.LIMIT });
    commit('setLoading', true);
    const client = await dispatch('keplr/getClient', chainInfo, { root: true });
    const res = await dispatch(
      'keplr/signAndBroadcastTransaction',
      { msgs: [msg], fee, client, translator, context },
      {
        root: true,
      },
    );
    commit('setLoading', false);
    return res;
  },
};
