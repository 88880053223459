import axios from 'axios';
import { cosmwasmApi } from '@/apis';
import { CHAIN, NFT, SETTINGS } from '@/constants';
import { stringHandler } from '@/utils';

export default {
  async initMyNftList({ commit, dispatch, rootGetters }) {
    commit('reset');
    commit('setLoading', true);
    if (!rootGetters['keplr/wallet']) {
      commit('setHasWallet', false);
      commit('setLoading', false);
      return;
    }
    const requests = [dispatch('fetchNfts')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  async fetchNfts({ commit, dispatch }) {
    const contracts = await dispatch('getAllContracts');
    if (!contracts.length) {
      return;
    }
    for (const address of contracts) {
      const tokenIds = await dispatch('getTokenIds', address);
      if (!tokenIds.length) {
        continue;
      }
      for (const tokenId of tokenIds) {
        const nft = await dispatch('getNftInfo', { address, tokenId });
        if (nft) {
          commit('addNft', nft);
        }
      }
    }
  },
  async getAllContracts({ commit }) {
    const allContracts = [];
    const limit = SETTINGS.LIMIT.MEDIUM;
    const codeIds = [CHAIN.WASM_CODE_ID.NFT_BASE, CHAIN.WASM_CODE_ID.NFT_CHAIN];
    for (const codeId of codeIds) {
      let nextKey = null;
      do {
        try {
          const response = await cosmwasmApi.requestContracts({
            codeId,
            limit,
            nextKey,
          });
          allContracts.push(...response.data.contracts);
          nextKey = response.data.pagination.next_key;
        } catch (error) {
          commit('setError', error);
        }
      } while (nextKey);
    }
    return allContracts;
  },
  async getTokenIds({ commit, rootGetters }, address) {
    const wallet = rootGetters['keplr/wallet'];
    const query = `{"tokens":{"owner":"${wallet}"}}`;
    const queryData = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: queryData,
      });
      return response.data.data.tokens;
    } catch (error) {
      commit('setError', error);
    }
  },
  async getNftInfo({ commit }, { address, tokenId }) {
    const query = `{"nft_info":{"token_id":"${tokenId}"}}`;
    const queryData = stringHandler.encodeToBase64(query);
    try {
      const response = await cosmwasmApi.requestContractSmartQuery({
        address,
        query_data: queryData,
      });
      const data = response.data.data;
      const nft = {
        id: tokenId,
        contract: address,
      };
      if (data.extension) {
        nft[NFT.METADATA_KEY.CHAIN] = { ...data.extension };
      }
      if (data.token_uri) {
        try {
          const response = await axios.get(data.token_uri);
          if (response) {
            nft[NFT.METADATA_KEY.BASE] = { ...response.data };
          }
        } catch (error) {
          nft[NFT.METADATA_KEY.BASE] = null;
        }
      }
      return nft;
    } catch (error) {
      commit('setError', error);
    }
  },
};
