const alerts = {
  amonutHigherThanMaximumPossible: 'The amount is higher than the maximum possible',
  amonutLessThanBalance: 'The amount must be less than the balance',
  amonutLessThanMinimumRequired: 'The amount is less than the minimum required',
  amountGreaterZero: 'Amount must be greater than or equal to zero',
  connected: 'Connected',
  dataNotAvailable: 'At this point in time the data, unfortunately, is not available.',
  failedToDetectTheChain: 'Failed to detect the chain',
  failedToSuggestTheChain: 'Failed to suggest the chain',
  higherAmount: 'The amount is higher than available',
  installKeplrExtension: 'Please install Keplr extension',
  invalid: 'Invalid',
  invalidJson: 'Invalid JSON',
  mandatoryField: 'This field is mandatory',
  noAccountFound: 'No accounts found. Use the Keplr extension to create it or import it.',
  noCode: 'No code available',
  noDataAvailable: 'No data available',
  noMessage: 'No message available',
  noWalletConnected: 'No wallet connected, in order to use this function you must activate one using the button in the bar',
  onlyCharsAndDash: 'Only characters and \'-\' are allowed',
  pageNotFound: '404 - Page not found',
  positiveAmount: 'The amount must be positive',
  searchEvents: 'Events can be filtered by date, type and hash; the date must be in the format YYYY-MM-DD',
  symbolLength: 'The symbol must be between 3 and 12 characters long',
  tooLongField: 'The value entered is too long',
};

export default alerts;
