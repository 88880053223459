import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const DexPageList = loadPage('dex/DexPageList');
const DexPageManagementAdd = loadPage('dex/DexPageManagementAdd');
const DexPageManagementDashboard = loadPage('dex/DexPageManagementDashboard');
const DexPageManagementRemove = loadPage('dex/DexPageManagementRemove');
const DexPageManagementSwap = loadPage('dex/DexPageManagementSwap');
const DexPageNew = loadPage('dex/DexPageNew');
const DexRouter = loadPage('dex/DexRouter');
const DexRouterManagement = loadPage('dex/DexRouterManagement');

const dexRoute = {
  path: ROUTING.PATH.DEX,
  component: DexRouter,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.DEX,
  },
  children: [
    {
      path: '',
      name: ROUTING.NAME.DEX,
      component: DexPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTING.PATH.DEX_LIST,
      name: ROUTING.NAME.DEX_LIST,
      component: DexPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.DEX_LIST,
      },
    },
    {
      path: ROUTING.PATH.DEX_MANAGEMENT,
      component: DexRouterManagement,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.DEX_MANAGEMENT,
      },
      children: [
        {
          path: '',
          name: ROUTING.NAME.DEX_MANAGEMENT,
          component: DexPageManagementDashboard,
          props: true,
          meta: {
            auth: ROUTING.USER_ROLE.AUTHORIZED,
            tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
          },
        },
        {
          path: ROUTING.PATH.DEX_MANAGEMENT_DASHBOARD,
          name: ROUTING.NAME.DEX_MANAGEMENT_DASHBOARD,
          component: DexPageManagementDashboard,
          props: true,
          meta: {
            auth: ROUTING.USER_ROLE.AUTHORIZED,
            tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
            title: ROUTING.TITLE.DEX_MANAGEMENT_DASHBOARD,
          },
        },
        {
          path: ROUTING.PATH.DEX_MANAGEMENT_ADD,
          name: ROUTING.NAME.DEX_MANAGEMENT_ADD,
          component: DexPageManagementAdd,
          meta: {
            auth: ROUTING.USER_ROLE.AUTHORIZED,
            tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
            title: ROUTING.TITLE.DEX_MANAGEMENT_ADD,
          },
        },
        {
          path: ROUTING.PATH.DEX_MANAGEMENT_REMOVE,
          name: ROUTING.NAME.DEX_MANAGEMENT_REMOVE,
          component: DexPageManagementRemove,
          meta: {
            auth: ROUTING.USER_ROLE.AUTHORIZED,
            tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
            title: ROUTING.TITLE.DEX_MANAGEMENT_REMOVE,
          },
        },
        {
          path: ROUTING.PATH.DEX_MANAGEMENT_SWAP,
          name: ROUTING.NAME.DEX_MANAGEMENT_SWAP,
          component: DexPageManagementSwap,
          meta: {
            auth: ROUTING.USER_ROLE.AUTHORIZED,
            tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
            title: ROUTING.TITLE.DEX_MANAGEMENT_SWAP,
          },
        },
      ],
    },
    {
      path: ROUTING.PATH.DEX_NEW,
      name: ROUTING.NAME.DEX_NEW,
      component: DexPageNew,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.DEX_NEW,
      },
    },
  ],
};

export default dexRoute;
