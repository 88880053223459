const fieldValidator = {
  /**
   *
   * @param {String} value
   * @returns {Boolean}
   */
  isJsonString(value) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  },
  /**
   *
   * @param {String} value
   * @returns {Boolean}
   */
  isSymbolValid(value) {
    const regExp = new RegExp('^[a-zA-Z-]{3,12}$', 'igm');
    return regExp.test(value);
  },
  /**
   *
   * @param {Array} items
   * @returns {Boolean}
   */
  hasPositiveBalances(items) {
    return items.every((it) => parseInt(it.amount) > 0);
  },
};

export default fieldValidator;
