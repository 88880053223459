import { CHAIN } from '@/constants';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  hasWallet: (state) => state.hasWallet,
  cw20s: (state) =>
    state.cw20s.map((el) => ({
      type: CHAIN.TOKEN_TYPE.CW20,
      text: el.name,
      symbol: el.symbol,
      value: el.contract,
    })),
  natives: (state) =>
    state.natives.map((el) => ({
      type: CHAIN.TOKEN_TYPE.NATIVE,
      text: el.denom,
      symbol: el.denom.slice(0, 4),
      value: el.denom,
    })),
  contract: (state) => state.contract,
  isValidContract: (state) =>
    state.contract &&
    state.contract[`${CHAIN.TOKEN_DENOM.TOKEN_1}_denom`] &&
    state.contract[`${CHAIN.TOKEN_DENOM.TOKEN_2}_denom`] &&
    state.contract['lp_fee_percent'] &&
    state.contract['protocol_fee_percent'] &&
    state.contract['protocol_fee_recipient'],
};
