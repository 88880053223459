import { walletsManager } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  list: (state) => state.list,
  wallets: (state) => {
    const wallets = walletsManager.mergeWallets(state.list);
    return walletsManager.sumWalletValues(wallets);
  },
};
