const msgs = {
  addingAMarketer: 'Aggiungere un responsabile marketing è obbligatorio per poter modificare in futuro il marketing',
  addingAMinter: 'Aggiungere un minter è obbligatorio per poter eseguire il mint di ulteriori token',
  assertAndViewTx:
    'La transazione è stata eseguita con successo e può essere visualizzata a questo link',
  copied: 'Copiato',
  enterOneWalletAndBalance: 'Inserire almeno un indirizzo di wallet ed il saldo iniziale',
  noCode: 'Nessun codice',
  noMessage: 'Nessun messaggio',
  triggeredAnError: 'È successo qualcosa nell\'impostazione della richiesta che ha generato un errore',
  unansweredRequest: 'La richiesta è stata fatta ma non è stata ricevuta alcuna risposta',
  welcome: 'Benvenuto nell\'applicazione di Commercio.network dove poter visualizzare i wallet e gestire gli smart contracts',
};

export default msgs;
