<template>
  <v-btn icon :to="to">
    <v-icon color="secondary" size="18">{{ mdiPlus }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiPlus } from '@mdi/js';

export default {
  name: 'BaseDrawerAddButton',
  props: {
    to: {
      type: Object,
      required: true,
      note: "The item's link",
    },
  },
  data() {
    return {
      mdiPlus,
    };
  },
};
</script>
