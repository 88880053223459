<template>
  <v-sheet>
    <v-progress-linear :color="color" :height="height" :indeterminate="true" />
  </v-sheet>
</template>

<script>
export default {
  name: 'BaseLoadingIndicator',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    height: {
      type: [String, Number],
      default: 15,
    },
  },
};
</script>
