const BECH32_PREFIX = 'did:com:';

const PREFIX = Object.freeze({
  ACCOUNT: {
    ADDRESS: `${BECH32_PREFIX}`,
    KEY: `${BECH32_PREFIX}pub`,
  },
  VALIDATOR: {
    CONSENSUS: {
      ADDRESS: `${BECH32_PREFIX}valcons`,
      KEY: `${BECH32_PREFIX}valconspub`,
    },
    OPERATOR: {
      ADDRESS: `${BECH32_PREFIX}valoper`,
      KEY: `${BECH32_PREFIX}valoperpub`,
    },
  },
});

const STABLE_COIN = Object.freeze({
  NAME: 'commercio cash credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
});

const TOKEN = Object.freeze({
  NAME: 'commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
});

const TOKENS_AMOUNT = 60 * 1000000;

const GAS = Object.freeze({
  LIMIT: 1000000,
  PRICE: {
    AVERAGE: 0.125,
    HIGH: 0.15,
    LOW: 0.1,
  },
});

const TOKEN_TYPE = Object.freeze({
  CW20: 'cw20',
  NATIVE: 'native',
});

const TOKEN_KEY = Object.freeze({
  TOKEN_1: 'Token1',
  TOKEN_2: 'Token2',
});

const TOKEN_DENOM = Object.freeze({
  TOKEN_1: 'token1',
  TOKEN_2: 'token2',
});

const WASM_CODE_ID = Object.freeze({
  CW20: process.env.VUE_APP_CODE_ID_CW20,
  DEX: process.env.VUE_APP_CODE_ID_DEX,
  NFT_BASE: process.env.VUE_APP_CODE_ID_NFT_BASE,
  NFT_CHAIN: process.env.VUE_APP_CODE_ID_NFT_CHAIN,
});

const WASM_CODE_LABEL = Object.freeze({
  [WASM_CODE_ID.CW20]: 'CW20',
  [WASM_CODE_ID.DEX]: 'Dex',
  [WASM_CODE_ID.NFT_BASE]: 'NFT base',
  [WASM_CODE_ID.NFT_CHAIN]: 'NFT chain',
});

const WASM_CW20_CONTRACTS = process.env.VUE_APP_CW20_CONTRACTS;

const INFO = Object.freeze({
  rpc: process.env.VUE_APP_CHAIN_RPC,
  rest: process.env.VUE_APP_CHAIN_LCD,
  chainId: process.env.VUE_APP_CHAIN_ID,
  chainName: process.env.VUE_APP_CHAIN_NAME,
  stakeCurrency: {
    coinDenom: TOKEN.SYMBOL,
    coinMinimalDenom: TOKEN.DENOM,
    coinDecimals: TOKEN.EXPONENT,
  },
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: PREFIX.ACCOUNT.ADDRESS,
    bech32PrefixAccPub: PREFIX.ACCOUNT.KEY,
    bech32PrefixValAddr: PREFIX.VALIDATOR.OPERATOR.ADDRESS,
    bech32PrefixValPub: PREFIX.VALIDATOR.OPERATOR.KEY,
    bech32PrefixConsAddr: PREFIX.VALIDATOR.CONSENSUS.ADDRESS,
    bech32PrefixConsPub: PREFIX.VALIDATOR.CONSENSUS.KEY,
  },
  currencies: [
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
    },
    {
      coinDenom: STABLE_COIN.SYMBOL,
      coinMinimalDenom: STABLE_COIN.DENOM,
      coinDecimals: STABLE_COIN.EXPONENT,
    },
  ],
  feeCurrencies: [
    {
      coinDenom: STABLE_COIN.SYMBOL,
      coinMinimalDenom: STABLE_COIN.DENOM,
      coinDecimals: STABLE_COIN.EXPONENT,
      gasPriceStep: {
        low: GAS.PRICE.LOW,
        average: GAS.PRICE.AVERAGE,
        high: GAS.PRICE.HIGH,
      },
    },
    {
      coinDenom: TOKEN.SYMBOL,
      coinMinimalDenom: TOKEN.DENOM,
      coinDecimals: TOKEN.EXPONENT,
      gasPriceStep: {
        low: GAS.PRICE.LOW,
        average: GAS.PRICE.AVERAGE,
        high: GAS.PRICE.HIGH,
      },
    },
  ],
});

export default Object.freeze({
  EXPLORER_URL: process.env.VUE_APP_CHAIN_EXPLORER,
  GAS,
  INFO,
  PREFIX,
  STABLE_COIN,
  TOKEN,
  TOKENS_AMOUNT,
  TOKEN_DENOM,
  TOKEN_KEY,
  TOKEN_TYPE,
  WASM_CODE_ID,
  WASM_CODE_LABEL,
  WASM_CW20_CONTRACTS,
});
