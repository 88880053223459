import { KEYCLOAK_CONFIG, ROUTING } from '@/constants';
import { loadPage } from '../index';

const NftPageList = loadPage('nft/NftPageList');
const NftPageManagement = loadPage('nft/NftPageManagement');
const NftPageNew = loadPage('nft/NftPageNew');
const NftRouter = loadPage('nft/NftRouter');

const nftRoute = {
  path: ROUTING.PATH.NFT,
  component: NftRouter,
  meta: {
    auth: ROUTING.USER_ROLE.AUTHORIZED,
    tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
    title: ROUTING.TITLE.NFT,
  },
  children: [
    {
      path: '',
      name: ROUTING.NAME.NFT,
      component: NftPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
      },
    },
    {
      path: ROUTING.PATH.NFT_LIST,
      name: ROUTING.NAME.NFT_LIST,
      component: NftPageList,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.NFT_LIST,
      },
    },
    {
      path: ROUTING.PATH.NFT_MANAGEMENT,
      name: ROUTING.NAME.NFT_MANAGEMENT,
      component: NftPageManagement,
      props: true,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.NFT_MANAGEMENT,
      },
    },
    {
      path: ROUTING.PATH.NFT_NEW,
      name: ROUTING.NAME.NFT_NEW,
      component: NftPageNew,
      meta: {
        auth: ROUTING.USER_ROLE.AUTHORIZED,
        tags: [KEYCLOAK_CONFIG.USER_ATTRIBUTE.VALUE],
        title: ROUTING.TITLE.NFT_NEW,
      },
    },
  ],
};

export default nftRoute;
