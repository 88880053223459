const BACKEND = process.env.VUE_APP_CHAIN_BACKEND;
const LCD = process.env.VUE_APP_CHAIN_LCD;
const API_VERSION = process.env.VUE_APP_API_VERSION;

export default Object.freeze({
  AUTH: `${LCD}/cosmos/auth/${API_VERSION}`,
  BANK: `${LCD}/cosmos/bank/${API_VERSION}`,
  COSWMWASM: `${LCD}/cosmwasm/wasm/v1`,
  DISTRIBUTION: `${LCD}/cosmos/distribution/${API_VERSION}`,
  STAKING: `${LCD}/cosmos/staking/${API_VERSION}`,
  TX: `${LCD}/cosmos/tx/${API_VERSION}`,
  WASMS: `${BACKEND}/api/v2/wasms`,
});
