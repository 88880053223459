<template>
  <v-sheet>
    <v-alert border="left" outlined prominent :type="kind">
      <template>
        <span class="text-body-2" v-text="alert" />
      </template>
    </v-alert>
  </v-sheet>
</template>

<script>
export default {
  name: 'BaseAlertMessage',
  props: {
    alert: {
      type: String,
      required: true,
      note: 'The message to show',
    },
    kind: {
      type: String,
      default: 'info',
    },
  },
};
</script>
