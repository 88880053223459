<template>
  <v-app>
    <v-container v-if="isLoading" fluid fill-height data-test="loading">
      <v-layout justify-center align-center>
        <v-progress-circular
          color="primary"
          indeterminate
          size="100"
          width="15"
        />
      </v-layout>
    </v-container>
    <v-container v-else-if="!isLoading && error">
      <v-alert type="error">
        <span v-text="error" />
      </v-alert>
    </v-container>
    <div v-else>
      <the-app-bar />
      <v-main id="main" :style="style">
        <v-container>
          <router-view />
        </v-container>
      </v-main>
      <the-footer />
    </div>
  </v-app>
</template>

<script>
import TheAppBar from '@/layout/TheAppBar.vue';
import TheFooter from '@/layout/TheFooter.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    TheAppBar,
    TheFooter,
  },
  computed: {
    ...mapGetters(['error', 'isLoading']),
    style() {
      return { background: this.$vuetify.theme.themes.light.background };
    },
  },
};
</script>
