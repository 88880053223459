<template>
  <v-list dense nav>
    <base-drawer-menu-item
      :title="$t('titles.dashboard')"
      :to="{ name: ROUTING.NAME.DASHBOARD }"
    />
    <base-drawer-menu-item
      :title="$t('titles.wallets')"
      :to="{ name: ROUTING.NAME.WALLETS }"
    />
    <base-drawer-menu-item
      :title="$t('titles.migration')"
      :to="{ name: ROUTING.NAME.MIGRATION }"
    />
    <base-drawer-menu-item
      :title="$t('titles.cw20List')"
      :to="{ name: ROUTING.NAME.CW_20 }"
    >
      <template>
        <base-drawer-add-button :to="{ name: ROUTING.NAME.CW_20_NEW }" />
      </template>
    </base-drawer-menu-item>
    <base-drawer-menu-item
      :title="$t('titles.dexList')"
      :to="{ name: ROUTING.NAME.DEX }"
    >
      <template>
        <base-drawer-add-button :to="{ name: ROUTING.NAME.DEX_NEW }" />
      </template>
    </base-drawer-menu-item>
    <base-drawer-menu-item
      :title="$t('titles.nftList')"
      :to="{ name: ROUTING.NAME.NFT }"
    >
      <template>
        <base-drawer-add-button :to="{ name: ROUTING.NAME.NFT_NEW }" />
      </template>
    </base-drawer-menu-item>
    <base-drawer-menu-item
      :title="$t('titles.myNft')"
      :to="{ name: ROUTING.NAME.MY_NFT }"
    />
    <base-drawer-menu-item
      :title="$t('titles.tos')"
      :to="{ name: ROUTING.NAME.TOS }"
    />
    <base-drawer-menu-item
      :title="$t('titles.whitepaperDrafter')"
      :to="{ name: ROUTING.NAME.WHITEPAPER_DRAFTER }"
    />
  </v-list>
</template>

<script>
import BaseDrawerAddButton from './BaseDrawerAddButton.vue';
import BaseDrawerMenuItem from './BaseDrawerMenuItem.vue';

import { ROUTING } from '@/constants';

export default {
  name: 'TheDrawerRoutes',
  components: {
    BaseDrawerAddButton,
    BaseDrawerMenuItem,
  },
  data() {
    return {
      ROUTING,
    };
  },
};
</script>
